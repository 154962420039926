<template>
    <div>
        <TableLoader v-if="loading && !disableLoadingAnimation" :overlay="true" />

        <div v-if="enableMultiSelect" style="position: sticky; top: 0; width: 100%; z-index: 1000; background-color: #e0e1e2;"> 
            <table class="table table-md" style="margin-bottom: -0.2rem;">
                <col width="23px" v-if="multiSelect"/>
                <col v-if="multiSelect"/>
                <col v-if="multiSelect"/>
                <col v-if="!multiSelect"/>
                <col width="50px"/>
                <tbody>
                    <tr>
                        <td v-if="multiSelect">
                            <sui-checkbox 
                                style="padding-left: 0; padding-top: 2px;"
                                v-model="multiSelectAll"
                                @change="selectDeselectAll()"
                            ></sui-checkbox>
                        </td>
                        <td v-if="multiSelect">
                            <span>{{ multiSelectCount }} valgt </span>
                        </td>
                        <td v-if="multiSelect" style="padding: 5px;"> 
                            <sui-button
                                :disabled="multiSelectCount == 0"
                                floated="right"
                                size="mini"
                                title="Action"
                                class="menu-text-button"
                                @click="multiSelectAction()"
                            >Actions</sui-button> 
                        </td>
                        <td v-if="!multiSelect">
                        </td>
                        <td style="padding: 5px;">
                            <sui-button
                                v-if="!multiSelect"
                                floated="right"
                                size="mini"
                                title="Multi select"
                                class="menu-icon-button"
                                @click="enableMultiSelectHandler()"
                            ><i class="fa-solid fa-square-check"></i></sui-button> 
                            <sui-button
                                v-if="multiSelect"
                                floated="right"
                                size="mini"
                                title="Annullér valg"
                                class="menu-icon-button"
                                @click="cancelMultiSelectHandler()"
                            ><i class="fa-sharp fa-solid fa-xmark"></i></sui-button> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <template v-if="!loading">
            <table v-for="(booking, groupKey) in this.sortedBookings" :key="groupKey" class="table table-striped table-hover table-md timeline-table">
                <col width="23px" v-if="multiSelect"/>
                <col class="address-col" :class="enlargeAddressField"/>
                <col width="90px" class="timeframe-col" v-if="showTagsCol"/>
                <col width="140px" class="short-description-col" v-if="settings.get('showMoreTrtData')"/>
                <col width="75px" class="timeframe-col" v-if="showBookingData"/>
                <col width="52px" class="initials-col" v-if="showWorker"/>
                <col width="100px" class="dueDate-col" v-if="showDueDate && hasTickets"/>
                <thead>
                    <tr>
                        <th :colspan="columnCount">
                            <span :class="groupKeyIsPastDate(groupKey) && showBookingData ? 'past-date' : ''">
                                {{ headerFromGroupKey(groupKey, settings) }}
                            </span>
                            <span v-if="settings.get('showCounters')" style="float: right; opacity: 0.7;">
                                <span v-if="countUniqueWorkers(groupKey)" style="margin-right: 5px" :title="`${countUniqueWorkers(groupKey)} montør${countUniqueWorkers(groupKey) > 1 ? 'er' : ''} med aftaler på ${headerFromGroupKey(groupKey, settings)}`">
                                    {{ countUniqueWorkers(groupKey) }}
                                    <i v-if="specialOccasion == 'christmas'" class="fa-kit fa-nisse"></i>
                                    <i v-else class="fas fa-user-hard-hat"></i>
                                </span>
                                <span :title="`${bookings[groupKey].length} installation${bookings[groupKey].length > 1 ? 'er' : ''} ${headerFromGroupKey(groupKey, settings) ? headerFromGroupKey(groupKey, settings) : ''}`">
                                    {{ bookings[groupKey].length }}
                                    <i class="fas fa-house-user"></i>
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(ins, index) in booking"
                        :key="index"
                        :class="classes(
                            (insContainsOnlyState(ins, TaskState.PENDING) && !showFlag(ins)) ? 'disabled' : '',
                            activeInstallationLabel == ins.label ? 'highlight' : '',
                            ins.appointment && ins.appointment.AppointmentType == AppointmentType.CUSTOM && ins.appointment.LinkedProjects.length ? 'projectCustom' : '',
                            ins.appointment && ins.appointment.AppointmentType == AppointmentType.CUSTOM && !ins.appointment.LinkedProjects.length ? 'globalCustom' : '',
                            settings.get('highlightOwnAppointments') && ins.appointment && appointmentBelongsToUser(ins.appointment, user.email) ? 'myAppointment' : '',
                        )"
                        :warning="ins && ins.appointment && ins.appointment.AppointmentType == AppointmentType.CUSTOM && !ins.appointment.LinkedProjects.length"
                        :danger="ins && ins.appointment && ins.appointment.AppointmentType == AppointmentType.CUSTOM && !ins.appointment.LinkedProjects.length"
                        @click="(event) => event.shiftKey ? enableMultiSelectHandler(ins) : timelineItemClick(ins.label, ins.instId)"
                        @contextmenu.prevent="currentMedia.type != 'Desktop' ? enableMultiSelectHandler(ins) : null"
                    >
                        <td v-if="multiSelect">
                            <sui-checkbox 
                                :id="ins.label"
                                v-model="ins.selected"
                                @change="multiSelectSelectorOnChange()"
                                @click.stop
                                style="padding-left: 0; padding-top: 2px;"
                            ></sui-checkbox>
                        </td>

                        <!-- Appointment Address and Icon Field -->
                        <td class="address-cell" v-if="ins.appointment && ins.appointment.AppointmentType == AppointmentType.CUSTOM" :colspan="getColCountAddress(ins)">
                            {{ins.appointment.Title}}
                        </td>
                        <td class="address-cell" :colspan="getColCountAddress(ins)"
                            v-else-if="(ins.tasks && ins.tasks[0] && getConfigurationInstallation(ins)) || (ins.appointment && ins.appointment.AddressLong)" 
                            :style="showFlag(ins) ? 'font-weight: bold; color: tomato;' : ''"
                        >
                            <span
                                v-if="settings.get('showNavigationIcon')"
                            >
                                <i v-if="(ins.tasks && ins.tasks[0] && getConfigurationInstallation(ins) && getConfigurationInstallation(ins).address) || (ins.appointment && ins.appointment.AddressLong)" 
                                    class="fas fa-map-marker-alt" style="color: #007bff" :title="`${ ins.appointment ? ins.appointment.AddressLong : formatAddress(getConfigurationInstallation(ins).address, false)}\nKlik for at navigere i Google Maps`"
                                    @click.stop="linkToGoogleMapsDirections(ins.coordinates, (ins.appointment ? ins.appointment.AddressLong : formatAddress(getConfigurationInstallation(ins).address, false)))"
                                ></i>
                                <i v-else class="fas fa-map-marker-alt" style="color: gray;" :title="`Der er ikke angivet en adresse at navigere til`"></i>
                            </span>

                            <!-- Change status shortcut icon -->
                            <span 
                                v-if="settings.get('showCloseCPETaskShortcut') && ins.appointment && ins.appointment.Date == formatMachineDate(new Date()) && !(ins.tasks[0] && ins.tasks[0].installation ? (insContainsOnlyState(ins, TicketState.CLOSED) || insContainsOnlyState(ins, TicketState.RESOLVED)) : insContainsOnlyState(ins, TaskState.CLOSED_COMPLETE))"
                                title="Skift status på installations-opgave"
                                @click.stop="shortcutToCloseTask(ins)"
                            >
                                <i class="fa-solid fa-badge-check" style="color: black;"></i>
                            </span>

                            <!-- Flagged appointment icon -->
                            <span v-if="showFlag(ins)">
                                <i class="fa-solid fa-flag" style="color: tomato;" title="Husk at læse noterne inden du kører ud til kunden"></i>
                            </span>

                            <!-- Tags -->
                            <span v-if="showTags && !showTagsCol && showTag(ins) && getTagsFromIns(ins).length == 1">
                                <i :class="`fa-solid fa-tag ${getTagsFromIns(ins)[0]}`" :title="getTagTitle(ins)"></i>
                            </span>
                            <span v-else-if="showTags && !showTagsCol && showTag(ins) && getTagsFromIns(ins).length > 1">
                                <i :class="`fa-solid fa-tags ${getTagsFromIns(ins)[0]}`" :title="getTagTitle(ins)"></i>
                            </span>

                            <!-- Appointment locked for rebooking icon -->
                            <span v-if="ins.appointment && ins.appointment.Lock">
                                <i class="fa-solid fa-lock" style="color: dimgray;" title="Aftalen er låst for ombooking"></i>
                            </span>
                            
                            <!-- Installation status icons -->
                            <InstallationStatusIcons
                                :installation="ins"
                                :settings="settings"
                            />
                            <span v-if="settings.get('showHubNoOnInst')" title="Accesshus for installationen">
                                {{ getHubName(ins) }}
                            </span>
                            {{ ins.appointment ? reformatAddress(ins.appointment.AddressLong, settings.get('showNumberBeforeRoad')) : ( getConfigurationInstallation(ins).address ? formatAddress(getConfigurationInstallation(ins).address, false, settings.get('showNumberBeforeRoad')) : getConfigurationInstallation(ins).label )}}    
                        </td>

                        <!-- Tags Field -->
                        <td v-if="showTagsCol" :class="`${getTagsFromIns(ins)[0]}-background`" :title="getTagTitle(ins)"> {{ ins.installationFirstSeen == 'N/A' ? '' : toUserFriendlyDate(ins.installationFirstSeen).split(' ')[1] }} </td>

                        <!-- Short Description Field -->
                        <td v-if="settings.get('showMoreTrtData') && getCurrentAppointmentType(ins) == AppointmentType.TICKET" class="short-description-cell">
                            <span v-if="ins.tasks && ins.tasks.length">
                                {{ ins.tasks[0].shortDescription }}<span v-if="index != ins.tasks.length-1">,</span>&nbsp;
                            </span>
                        </td>

                        <!-- Time Window Field -->
                        <td width="25px" class="timeframe-cell" v-if="showBookingData">
                            <span 
                                v-if="ins.appointment && ins.appointment.Time && ins.appointment.Time.From.toUpperCase().substr(0,1) != 'N' && ins.appointment.Time.To.toUpperCase().substr(0,1) != 'N'"
                                :title="`Aftalen ligger mellem klokken ${ins.appointment.Time.From} og ${ins.appointment.Time.To}`"
                            >
                                {{ ins.appointment.Time.From }} - {{ ins.appointment.Time.To }}
                            </span>
                        </td>
                    
                        <!-- Worker Field -->
                        <td width="15px" class="initials-cell" v-if="showWorker">
                            <span 
                                v-if="ins.appointment && ins.appointment.AppointmentType == AppointmentType.CUSTOM" 
                                :title="`${ins.appointment.WorkerInitials} er booket til denne aftale`"
                            >
                                {{ins.appointment.WorkerInitials}}
                            </span>
                            <span 
                                v-else-if="ins.appointment && ins.appointment.Worker" 
                                :title="`${ins.appointment.Worker.Name} er booket til denne aftale${(assigneeMismatch(ins) ? `\n${primaryAssignee(ins)} er assignet opgaven i PilotBI` : '')}`"
                            >
                                <i class="fa-solid fa-user-xmark" v-if="assigneeMismatch(ins)" style="color: tomato;"></i>
                                {{ parseInitials(ins.appointment.Worker.Email) }}
                            </span>
                            <span
                                v-else-if="ins.updates && !(ins.updates.Worker == null || ins.updates.Worker == 'Ingen')"
                            >
                                <i class="fa-solid fa-user-xmark" v-if="assigneeMismatch(ins)" style="color: tomato;"></i>
                                {{ parseInitials(ins.updates.Worker) }}
                            </span>
                            <span 
                                v-else-if="ins.tasks && ins.tasks[0] && ins.tasks[0].assignee"
                                :title="`${ins.tasks[0].assignee} er assignet opgaven i PilotBI`"
                            >
                                {{ initialsFromName(ins.tasks[0].assignee) }}
                            </span>
                        </td>

                        <!-- Deadline Field -->
                        <td class="dueDate-cell" v-if="showDueDate && hasTickets">
                            <span v-if="ins.tasks && ins.tasks[0] && ins.tasks[0].dueDate" :title="`Deadline sat af EFB: ${toUserFriendlyDate(ins.tasks[0].dueDate)}`">
                                {{ toUserFriendlyDate(ins.tasks[0].dueDate) }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>    
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { Bookingmixin } from '../../lib/Bookingmixin'
import EventBus from '../../EventBus.js'
import TaskCode from '../../lib/Enums/TaskCode.js'
import TaskState from '../../lib/Enums/TaskState.js'
import TicketState from '../../lib/Enums/TicketState.js'
import BookingItemHandlerType from '../../lib/Enums/BookingItemHandlerType.js'
import TableLoader from '../../components/TableLoader.vue'
import InstallationStatusIcons from './InstallationStatusIcons.vue'
import AppointmentType from '../../lib/Enums/AppointmentType.js'
import { DataAPI } from '@/lib/DataAPI.js'
import { ProjectSettingsMixin } from '@/modules/ProjectSettings/projectSettingsMixin.js'
import { mapGetters } from 'vuex'
import { specialOccasionsMixin } from '../../lib/Mixins/specialOccasionsMixin.js'

export default {
    name: 'ProjectTimeline',
    mixins: [Mixin, DateMixin, Bookingmixin, DataAPI, ProjectSettingsMixin, specialOccasionsMixin],

    components: {
        TableLoader,
        InstallationStatusIcons
    },

    props: {
        bookings: {
            type: [Object, Array],
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        settings: {
            type: Object,
            required: true,
        },
        showBookingData: {
            type: Boolean,
            required: false,
            default: true
        },
        showDueDate: {
            type: Boolean,
            required: false,
            default: false
        },
        itemClickHandlerType: {
            type: String,
            required: true
        },
        disableLoadingAnimation: {
            type: Boolean,
            required: false,
            default: false
        },
        enableMultiSelect: {
            type: Boolean,
            required: false,
            default: true // TODO: Change to false when multi select is implemented
        },
    },

    data() {
        return {
            activeInstallationLabel: null,
            setEnlargeField: false,

            multiSelect: false,
            onSelectChange: false,
            multiSelectAll: false,
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject',
        }),

        user() {
            return this.$root.$children[0].user
        },

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        multiSelectCount(){
            this.onSelectChange; // To trigger reactivity
            let count = 0;
            for (let i in Object.keys(this.bookings)){
                for (let j in this.bookings[Object.keys(this.bookings)[i]]){
                    if (this.bookings[Object.keys(this.bookings)[i]][j].selected) count++
                }
            }
            return count;
        },

        columnCount(){
            let colcount = 1 //Google maps link and Address cell
            if (this.showBookingData) colcount += 1 //Timeframe and 
            if (this.showWorker) colcount +=1 //Assignee cells
            if (this.showDueDate && this.hasTickets) colcount += 1 //Due date cell
            if (this.settings.get('showMoreTrtData')) colcount += 1 //Short description
            if (this.multiSelect) colcount += 1 //Multi select checkbox
            if (this.showTagsCol) colcount += 1 //Tags cell
            return colcount
        },

        enlargeAddressField(){
            let returnVal = 'address-col-standard'
            if (this.settings.get('showMoreTrtData') && this.hasTickets){
                if (this.currentMedia.type == 'Desktop' && this.settings.get('showMap')) {returnVal = 'address-col-desktop'}
                if (this.currentMedia.type == 'Mobile') {returnVal = 'address-col-mobile'}
            }
            return returnVal
        },

        sortedBookings(){
            const keys = Object.keys(this.bookings).sort((a,b) => {
                // If a or b is equal to '9999/12/31' it should always be first
                let sortOverrideProductDeliveryDate = this.getProjectSetting(this.project,"sortOverrideProductDeliveryDate")
                if (sortOverrideProductDeliveryDate){
                    if (a == '9999/12/31') return -1
                    if (b == '9999/12/31') return 1
                }

                if (this.settings.get('sortUnbookedPTBy') == 'TaskFirstSeen'){
                    if (a == 'N/A') return -1
                    if (b == 'N/A') return 1
                }

                // Normal sorting
                if (a > b) return this.sortAscending ? -1 : 1
                if (b > a) return this.sortAscending ? 1 : -1
                return 0
            })

            let returnValue = {}
            for (let i in keys) {
                let key = keys[i]
                returnValue[key] = this.bookings[key]
            }

            return returnValue
        },

        showWorker(){
            // If no workers are found return false

            return this.settings.get('showWorker')
        },

        showTags(){
            return this.settings.get('showTags')
        },

        showTagsCol(){
            if (!this.settings.get('showTags')) return false; // If tags are not shown, return false
            if (this.showBookingData) return false; // If booking data is shown, return false
 
            return !this.settings.get('showTagsAsIcons'); // If tags are shown as icons, return false
        },

        hasTickets(){
            let returnVal = false
            for (let i in this.bookings){
                for (let j in this.bookings[i]){
                    if (this.bookings[i][j]?.troubleTicketCount){
                        returnVal = true
                        break
                    }
                }
            }
            return returnVal
        },
    },

    enums: {
        TaskCode,
        TaskState,
        TicketState,
        BookingItemHandlerType,
        AppointmentType,
    },

    methods: {
        enableMultiSelectHandler(ins = null){
            if (!this.enableMultiSelect) return;
            this.multiSelect = true;
            if (!ins) return;
            if (!ins?.selected){
                this.$set(ins, 'selected', true);
            }
            else
            {
                this.$set(ins, 'selected', !ins.selected);
            }
            this.multiSelectSelectorOnChange();
        },

        cancelMultiSelectHandler(){
            this.multiSelect = false;
            this.multiSelectAll = false;
            this.deselectAll();
        },

        multiSelectSelectorOnChange(){
            this.onSelectChange = !this.onSelectChange;
        },

        selectDeselectAll(){
            if (this.multiSelectAll){
                this.selectAll();               
            } else {
                // Deselect all
                this.deselectAll();
            }
        },

        selectAll(){
            for (let i in Object.keys(this.bookings)){
                for (let j in this.bookings[Object.keys(this.bookings)[i]]){
                    let booking = this.bookings[Object.keys(this.bookings)[i]][j];
                    if (booking.appointment && booking.appointment.AppointmentType == AppointmentType.CUSTOM) continue;
                    booking.selected = true;
                }
            }
            this.multiSelectSelectorOnChange();
        },

        deselectAll(){
            for (let i in Object.keys(this.bookings)){
                for (let j in this.bookings[Object.keys(this.bookings)[i]]){
                    let booking = this.bookings[Object.keys(this.bookings)[i]][j];
                    if (booking.appointment && booking.appointment.AppointmentType == AppointmentType.CUSTOM) continue;
                    if (booking?.selected) {
                        delete booking.selected;
                    }
                }
            }
            this.multiSelectSelectorOnChange();
        },

        generateSelection(){
            let selection = []
            for (let i in Object.keys(this.bookings)){
                for (let j in this.bookings[Object.keys(this.bookings)[i]]){
                    let booking = this.bookings[Object.keys(this.bookings)[i]][j];
                    if (booking.selected) selection.push(booking);
                }
            }
            return selection;
        },

        multiSelectAction(){
            let selection = this.generateSelection();
            console.log(selection);

            this.cancelMultiSelectHandler();
        },

        showFlag(ins){
            if (ins.appointment && ins.appointment.Flag) return true
            if (ins.appointment && !ins.appointment.Flag) return false

            if (ins.updates && ins.updates.Flag) return true

            return false
        },

        showTag(ins){
            if (ins.appointment && ins.appointment.Tags && ins.appointment.Tags.length) return true
            if (ins.appointment && !ins.appointment.Tags) return false

            if (ins.updates && ins.updates.Tags && ins.updates.Tags.length) return true

            return false
        },

        getTagsFromIns(ins){
            if (ins.appointment && ins.appointment?.Tags?.length) return ins.appointment.Tags
        
            if (ins.updates && ins.updates?.Tags?.length) return ins.updates.Tags

            return []
        },

        getColorFromTag(tag){
            // const element = document.querySelector(`.${tag}`);
            // const color = window.getComputedStyle(element).color;
            // console.log('color', color);

            // element.style.backgroundColor = color;

            return tag;
        },

        getTagTitle(ins){
            let tags = this.getTagsFromIns(ins)
            let tagString = 'Mærkat(er): '

            let tagsOnProject = this.project.Tags;

            tags.forEach(tag => {
                let tagOnProject = tagsOnProject.find(t => t.value == tag)
                tagString += tagOnProject.text + ', '
            })
            return tagString.slice(0, -2)
        },

        getColCountAddress(ins){
            if (!(this.getCurrentAppointmentType(ins) == AppointmentType.TICKET) && this.settings.get('showMoreTrtData')) 
            {
                this.setEnlargeField = true
                return 2
            }
            this.setEnlargeField = false
            return 1
        },

        getConfigurationItemName(task){
            if (TaskCode.taskHasCode(task.code, TaskCode.TICKET)) return "installation"
            return "configurationItem"
        },

        getHubName(inst){
            let cabinetName = inst.tasks[0]?.configurationItem?.cabinet?.name
            if (cabinetName != null){
                let subStr = cabinetName.split('-')[0].replaceAll(/\s/g, '')
                subStr = '(H' + subStr + ')' 
                return subStr
            }
            else
                return null

        },

        timelineItemClick(installationLabel, installationId) {

            if (this.itemClickHandlerType == BookingItemHandlerType.OPEN_INSTALLATION) {
                let aliasUrl = this.$route.path.includes('timeline') ? `timeline` : 'installations'
                let url = `/projects/${this.$route.params.projectIdentifier}/${aliasUrl}/${installationId}`
                this.$router.push({ path: url })
                return
            }
            
            if (this.itemClickHandlerType == BookingItemHandlerType.SEND_EVENT) {
                if (this.activeInstallationLabel != installationLabel) {
                    EventBus.$emit('project-timeline-item-clicked', installationLabel)
                } else {
                    EventBus.$emit('project-timeline-item-clicked', null)
                }
                return
            }
        },

        scrollActiveInstIntoView(){
            setTimeout(() => {
                const foundElement = document.getElementsByClassName('highlight')[0]
                // console.log(foundElement)
                if (!foundElement) return
                foundElement.scrollIntoView({behavior: "smooth", block: "center"})
            }, 200)
        },

        async onReceiveItemClicked(installationLabel) {
            if (this.currentMedia?.type != 'Desktop') {
                return
            }
            // console.log(`Timeline received click with label: ${installationLabel}`)
            if (installationLabel != this.activeInstallationLabel) {
                this.activeInstallationLabel = installationLabel
                this.scrollActiveInstIntoView()
            } else {
                this.activeInstallationLabel = null
            }
        },

        parseInitials(email) {
            if (!email) return ''
            return String(email)
                .substring(0, String(email).indexOf('@'))
                .toUpperCase()
        },

        countUniqueWorkers(date) {
            const tasks = this.bookings[date]
            let uniqueWorkerEmails = tasks.reduce((uniquearray, task) => {
                if (task?.appointment?.Worker?.Email && !uniquearray.some((email) => task.appointment.Worker.Email == email)) {
                    uniquearray.push(task.appointment.Worker.Email)
                }
                return uniquearray
            }, [])
            return uniqueWorkerEmails.length
        },

        initialsFromName(name) {
            let nameArray = name.split(' ')
            let initials = ''
            nameArray.forEach(element => {
                initials = initials + element.substr(0,1).toUpperCase()
            });
            return initials
        },

        groupKeyIsPastDate(groupKey) {
            if (groupKey.match(/([A-Za-z])/g)) return false //Dates do not contain letters
            return this.parseDate(groupKey) < new Date().setHours(0,0,0,0)
        },

        shouldShowShortcutToCloseTask(ins){
            console.log(ins)
            return true
        },

        shortcutToCloseTask(ins) {
            let cpeTask = ins.tasks.find(task => TaskCode.taskHasCode(task, TaskCode.CPE))
            if (!cpeTask) {
                console.error(`No CPE task on installation`, ins)
            }
            console.log(cpeTask)
            EventBus.$emit('shortcut-to-close-task', cpeTask)
        },

        /**
         * Takes a booking, and returns the assignee of the primary task, from PilotBI
         * @param {{tasks: Array<Object>, appointment: Object}} booking An instance of a booked installation
         */
        primaryAssignee(booking) {
            if (!booking.tasks.length) return null
            if (!AppointmentType.AppointmentTasks[booking.appointment.AppointmentType]?.Primary) {
                // console.error(`No primary task for appointment type ${booking.appointment.AppointmentType}`)
                return '' //If no primary task for appointment type, return false
            }
            const primaryCode = AppointmentType.AppointmentTasks[booking.appointment.AppointmentType].Primary.TaskCode
            const task = booking.tasks.find(t => t.code == primaryCode)
            try {
                if (!task) throw new Error(`No task with code '${primaryCode}' on installation '${booking.label}', in spite of it being a primary task for appointment type '${booking.appointment.AppointmentType}'`)
            } catch (error) {
                console.error(error)
                return '-Opgave ikke fundet-'
            }
            return task.assignee || 'Ingen'
        },

        assigneeMismatch(booking){
            if(!booking.appointment || !booking.appointment.Worker) return false
            if (!booking.appointment.Confirmed) return false
            if (!booking.tasks.length) return false
            if (!AppointmentType.AppointmentTasks[booking.appointment.AppointmentType]?.Primary) {
                // console.error(`No primary task for appointment type ${booking.appointment.AppointmentType}`)
                return false //If no primary task for appointment type, return false
            }
            const primaryCode = AppointmentType.AppointmentTasks[booking.appointment.AppointmentType].Primary.TaskCode
            let task = booking.tasks.find(t => t.code == primaryCode)
            try {
                if (!task) throw new Error(`No task with code '${primaryCode}' on installation '${booking.label}', in spite of it being a primary task for appointment type '${booking.appointment.AppointmentType}'`)
            } catch (error) {
                console.error(error)
                return false
            }
            // console.log(`${task.assignee} ${task.assignee != booking.appointment.Worker.Name ? '!=' : '=='} ${booking.appointment.Worker.Name}`)
            return task.assignee != booking.appointment.Worker.Name
        },
    },

    beforeMount() {
        EventBus.$on('project-timeline-item-clicked', this.onReceiveItemClicked.bind(this))
        EventBus.$on('okapi-map-item-clicked', this.onReceiveItemClicked.bind(this))
    },
}
</script>
<style scoped>
.menu-icon-button {
    padding: 9.87755px !important; 
    margin-top: -4px; 
    margin-bottom: -2px;
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.menu-text-button {
    padding-top: 9.87755px !important; 
    padding-bottom: 9.87755px !important; 
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-top: -4px; 
    margin-bottom: -2px;
    margin-left: 2px !important;
    margin-right: 2px !important;
}

/* For testing cell layout */
/* table, th, td { 
  border: 1px solid;
} */

table.timeline-table {
    width: 100%;
    table-layout: fixed;
}

tr.disabled td,
tr.disabled .text-danger,
tr.disabled .text-primary {
    color: #acacac !important;
}

.timeline-table tbody tr,
.timeline-table tbody tr td {
    cursor: pointer;
}

.card-body.p-0 .table tbody>tr>td:first-of-type {
    padding-right: 0.2rem;
}

table.timeline-table tr td {
    padding-bottom: 0.2rem;
}

table.timeline-table .address-cell i.fa,
table.timeline-table .address-cell i.fas,
table.timeline-table .address-cell i.far {
    margin-right: 2px;
}

table.timeline-table .timeframe-cell {
    white-space: nowrap;
}

table.timeline-table tr.projectCustom {
    background-color: rgb(255 204 0 / 15%);
    outline-style: auto;
    outline-color: rgb(255 204 0 / 40%);
}

table.timeline-table tr.globalCustom {
    background-color: rgb(199 20 26 / 15%);
    outline-style: auto;
    outline-color: rgb(199 20 26 / 40%);
}

table.timeline-table tr.highlight {
    background-color: rgb(3 123 228 / 15%) /* 037be4 */
}

.past-date {
    color: red;
}

.myAppointment {
    outline-color: rgb(3 123 228 / 100%) !important;
    outline-style: auto;
}
.address-text {
    width: inherit;
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.address-col-standard {
    min-width: 150px;
}
.address-col-mobile {
    min-width: 250px;
}
.address-col-desktop {
    min-width: 270px;
}
.address-cell {
    width: 100%;
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.timeframe-cell {
    min-width: 75px;
    padding-left: 2.8px !important;
    padding-right: 2.8px !important;
}
.initials-cell {
    min-width: 55px;
    padding-left: 2.8px !important;
    padding-right: 9.8px !important;
}

i {
    padding: 0 3px 0 3px;
}

</style>