<template v-if="apiResponse && serviceOrders">
    <div>
        <sui-table-body>
            <!-- <template v-for="serviceOrder of serviceOrders">
                <sui-table-row :key="`${serviceOrder.id}-number`">
                    <sui-table-header-cell>
                        Service Order
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <span v-if="apiResponse">{{serviceOrder.number}}</span> 
                        <sui-button size="mini" @click.prevent="openServiceOrderModal(serviceOrder)" style="float: right" icon="info"></sui-button>
                    </sui-table-header-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-sonWinId`">
                    <sui-table-cell>SonWin ID</sui-table-cell>
                    <sui-table-cell>{{serviceOrder.sonWinId}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-createdAt`">
                    <sui-table-cell>Oprettet</sui-table-cell>
                    <sui-table-cell>{{toUserFriendlyTimestamp(serviceOrder.createdAt, true, true)}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-shortDescription`">
                    <sui-table-cell>Kort beskrivelse</sui-table-cell>
                    <sui-table-cell>{{expandDescription(serviceOrder.project.shortDescription)}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-providerName`">
                    <sui-table-cell>Service Provider</sui-table-cell>
                    <sui-table-cell>{{serviceOrder.serviceProvider.name}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-state`">
                    <sui-table-cell>Status</sui-table-cell>
                    <sui-table-cell>{{serviceOrder.state.label}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-project`">
                    <sui-table-cell>Projekt</sui-table-cell>
                    <sui-table-cell>{{serviceOrder.project.number}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row :key="`${serviceOrder.id}-tasks`">
                    <sui-table-cell v-if="serviceOrder.project.tasks.length" style="font-weight: bold">
                        Opgaver tildelt FiberLAN
                    </sui-table-cell>
                    <sui-table-cell v-if="!serviceOrder.project.tasks.length" style="font-weight: bold">
                        Der er ingen opgaver tildelt til FiberLAN på denne Service Ordre
                    </sui-table-cell>
                    <sui-table-cell></sui-table-cell>
                </sui-table-row>
                <sui-table-row v-for="(task, index) in serviceOrder.project.tasks" :key="`${serviceOrder.id}-${index}`" selectable>
                    <sui-table-cell>
                        {{ task.shortDescription }} <br>
                        <sui-item style="font-size: small"> {{ task.number }} </sui-item>
                        <sui-item style="font-size: small"> {{ task.id }} </sui-item>
                    </sui-table-cell>
                    <sui-table-cell>
                        <sui-button
                            size="mini"
                            title="Vis task info"
                            @click="showTaskModal(task)"
                            style="margin-right: 5px;"
                        ><i class="fa-solid fa-info"></i></sui-button> 
                        <sui-button size="mini" :color="getStateColor(task.state)" @click.stop="taskStatusClicked()" >{{ task.state.label }}</sui-button>
                    </sui-table-cell>
                </sui-table-row>
            </template> -->
            <sui-message>
                <sui-message-header>ServiceOrdre kan ikke hentes på nyt API</sui-message-header>
                <p>
                    Pga ændringer i API'et fra EnergiFyn/VirkPlan, kan vi ikke længere hente ServiceOrdre på samme måde som tidligere.
                </p>
                <p>
                    Hvis det viser sig at være nødvendigt, vil vi arbejde på en anden løsning.
                </p>
            </sui-message>
        </sui-table-body>

        <show-task-data-modal 
            :isOpen="showTaskDataModal"
            :task="taskDataModalTask"
        />
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI.js'
import EventBus from '../../EventBus.js'
import swal from 'sweetalert'
import ShowTaskDataModal from '@/components/Project/ShowTaskDataModal.vue'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    components: {
        ShowTaskDataModal,
    },

    props: {
        instLabel: String,
    },

    data(){
        return {
            apiResponse: null,
            serviceOrders: null,

            showTaskDataModal: false,
            taskDataModalTask: {},
        }
    },

    methods: {
        closeTaskDataModal(){
            this.showTaskDataModal = false
            this.taskDataModalTask = {}
        },

        showTaskModal(task){
            this.taskDataModalTask = task
            this.showTaskDataModal = true
        },

        openServiceOrderModal(serviceOrder){
            EventBus.$emit('open-service-order-modal', serviceOrder)
        },

        async getNexelServiceOrders() {
            EventBus.$emit('function-activity', {functionName: 'NexelStatus_getNexelServiceOrders', isActive: true})
            this.apiResponse = null
            EventBus.$emit('retrieved-nexel-service-orders', {data: 'Henter service orders...', loading: true})
            if (!this.instLabel) {
                EventBus.$emit('retrieved-nexel-service-orders', {data: 'Intet installations-nr', loading: false})
                EventBus.$emit('function-activity', {functionName: 'NexelStatus_getNexelServiceOrders', isActive: false})
                return null
            }
            try {
                const resp = await this.dataGetAllServiceOrdersByInst(this.instLabel, true, true)
                this.apiResponse = resp
                if (!resp) throw new Error(`getNexelServiceOrders: Did not receive valid object from API`)
                EventBus.$emit('retrieved-nexel-service-orders', {data: this.apiResponse.data[0].installation.number, loading: false})
                if (this.apiResponse.data.length){
                    EventBus.$emit('retrieved-nexel-service-orders-title', `Service Orders (${this.apiResponse.data.length})`)
                } else {
                    EventBus.$emit('retrieved-nexel-service-orders-title', 'Service Orders')
                }

            } catch (error) {
                EventBus.$emit('retrieved-nexel-service-orders', {data: 'Kunne ikke hente data!', loading: false})
                console.error(error)
            }
            EventBus.$emit('function-activity', {functionName: 'NexelStatus_getNexelServiceOrders', isActive: false})
            this.serviceOrders = this.apiResponse.data
            return this.apiResponse
        },

        expandDescription(desc){
            if (desc != null) {
                let tmpStr = desc.split(' ')[0]
                if (tmpStr == "HC") return desc.replace("HC","House Connected")
                return desc.replace("HP","House Passed")
            }
        },

        taskStatusClicked(){
            swal('Kommende feature!', 'Det er på nuværende tidspunkt ikke muligt at ændre status på en opgave herfra.\n Der arbejdes på sagen.', 'info')
        },

    },

    beforeMount() {
        EventBus.$on('show-task-data-modal-close', () => {this.closeTaskDataModal()})
    },

    // mounted() {
    //     EventBus.$on('reload-nexel-service-orders-click', () => {this.getNexelServiceOrders()})
    // },

    beforeDestroy() {
        EventBus.$off('reload-nexel-service-orders-click')
    },

    // watch: {
    //     instLabel: {
    //         immediate: true,
    //         handler() {
    //             this.getNexelServiceOrders()
    //         }
    //     },
    // }
}
</script>
<style scoped>
    .sui-table-row tr:hover td {
        cursor: pointer;
    }
</style>