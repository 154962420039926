import axios from 'axios'
import swal from 'sweetalert'
import NetworkError from '../Errors/Network'
import EventBus from '../../EventBus'
import { analytics } from '../../firebase'

export default class PilotBi {

    /* -------------------------------------------------------------------------- */
    /*                           PROPERTIES AND CONFIGS                           */
    /* -------------------------------------------------------------------------- */

    BaseURL = "Not yet retrieved from firebase"
    SupplierID = "Not yet retrieved from firebase"
    APIKey = "Not yet retrieved from firebase"
    APIOnline = false

    AxiosConfig = {
        headers: {
            'x-api-key': this.APIKey,
            'Content-Type': 'application/json'
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                              HELPER FUNCTIONS                              */
    /* -------------------------------------------------------------------------- */

    stringifyPayload(payload){
        let keys = Object.keys(payload)
        let string = '?'
        for (let i in keys) {
            let key = keys[i]
            if (payload[key]){
                string += `${key}=${payload[key]}`
            }
        }
        if (string.length <= 1) return ''
        return string
    }

    getTime(date) {
        return `${String('0'+date.getHours()).substr(-2)}:${String('0'+date.getMinutes()).substr(-2)}`
    }

    isISODateString(str) {
        return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|Z)?$/.test(str);
    }

    convertToISO(timestamp) {
        if (this.isISODateString(timestamp)) {
            // It's already in ISO format, just return the standardized version
            return timestamp;
        } else {
            // Assume it's in local time and convert to UTC
            // const localDate = new Date(timestamp); // Interpreted as local time
            // const now = new Date();
            // const timeZoneOffset = now.getTimezoneOffset(); // Timezone offset in minutes
            // console.log('Timezone offset:', timeZoneOffset);
            // return new Date(localDate.getTime() - timeZoneOffset * 60000).toISOString();
            return new Date(timestamp).toISOString();
        }
    }

    async call(type, endpoint, payload = null, errorShouldPopup=true) {

        if (!this.APIOnline){
            swal('API nedetid', 'Pilotbi API er i øjeblikket nede. Prøv igen senere.', 'error')
            throw new Error('API is offline')
        }

        const perfStart = performance.now()
        const generalizedEndpoint = String(endpoint)
            .replace(this.SupplierID, '{{supplierId}}')
            .replace(/\d{4}-\d{2}-\d{2}/g, '{{date}}') //Date in ISO-format, followed by end of string
            .replace(/\d{1,3}-\d{3,4}(?=\/[HU]UB)/g, '{{UUBid}}') //UUB-ID followed by /HUB or /UUB
            .replace(/([0-9a-fA-F]{14,}(?=\/))/g, '{{hex}}') //14 or more hex digits in a row, followed by a /
            .replace(/([0-9a-fA-F]{14,}$)/g, '{{hex}}') //14 or more hex digits in a row, followed by end of string
            .replace(/([0-9]{4,}(?=\/))/g, '{{number}}') //4 or more digits in a row, followed by a /
            .replace(/([0-9]{4,}$)/g, '{{number}}') //4 or more digits in a row, followed by end of string
        
        try {
            let response;
            let stringifiedPayload
            switch (type) {
                case 'post':
                    response = await axios.post(`${this.BaseURL}${endpoint}`, payload, this.AxiosConfig)
                    break
                case 'patch':
                    response = await axios.patch(`${this.BaseURL}${endpoint}`, payload, this.AxiosConfig)
                    break
                case 'put':
                    response = await axios.put(`${this.BaseURL}${endpoint}`, payload, this.AxiosConfig)
                    break
                case 'delete':
                    response = await axios.delete(`${this.BaseURL}${endpoint}`, this.AxiosConfig)
                    break
                case 'get': 
                    stringifiedPayload = '';
                    if (payload){
                        stringifiedPayload = this.stringifyPayload(payload)
                    }
                    response = await axios.get(`${this.BaseURL}${endpoint}${stringifiedPayload}`, this.AxiosConfig)
                    break
                default:
                    response = await axios.get(`${this.BaseURL}${endpoint}`, this.AxiosConfig)
                    break
            }

            if (response.headers['x-ratelimit-limit']) {
                let rateLimitObj = {
                    remaining: response.headers['x-ratelimit-remaining'],
                    limit: response.headers['x-ratelimit-limit'],
                    reset: new Date(response.headers['x-ratelimit-reset'] * 1000)
                }
                EventBus.$emit('data-provider-api-ratelimit-usage', rateLimitObj)
            }
            const responseTime = Math.round(performance.now() - perfStart)
            analytics.logEvent('api_call_pilotbi', {generalizedEndpoint, responseTime, rateLimit: response.headers['x-ratelimit-limit'], rateLimitRemaining: response.headers['x-ratelimit-remaining'], reset: new Date(response.headers['x-ratelimit-reset'] * 1000)})

            return response
        }
        catch (xhrError) {

            console.log('is Online:', this.APIOnline)
            console.log('error should popup:', errorShouldPopup)

            if (xhrError.response?.status == 429) { // Too many requests
                let time = xhrError.response.headers['x-ratelimit-reset']
                time = this.getTime(new Date(time * 1000))
                swal(
                    'Pilotbi loft ramt.',
                    `Loftet for antal kald systemet må lave til pilotbi pr 10 min er desværre ramt. Loftet nulstilles kl ${time}`,
                    'error'
                )
            } else if (endpoint.substr(0,34) == '/v1/Technicals/Nexel/Installation/' && endpoint.substr(42) == '/Status' && xhrError.response?.status == 404) {
                console.dir(xhrError)
                if (errorShouldPopup){
                    swal(`Fejl: ASR svarer ikke`, `Følgende HTTP fejl fra Pilotbi/Nexel opstod i forsøget på at hente CPE linkstatus:\n${xhrError.response ? `${xhrError.response.status} ${xhrError.response.statusText}` : xhrError.message}.\n\nDet sker som regel når ASR'en ikke er aktiv for "data harvest" hos EnergiFyn, så prøv at høre driften om de vil aktivere den.`, 'error')
                }
            } else {
                console.dir(xhrError)
                if (errorShouldPopup){
                    swal(`HTTP Fejl: ${xhrError.response ? `${xhrError.response.status} ${xhrError.response.statusText}` : xhrError.message}`, `Følgende HTTP fejl fra Pilotbi opstod: ${xhrError.response ? `${xhrError.response.status} ${xhrError.response.statusText}` : xhrError.message}. ${xhrError.response?.data ? '\n\nData: '+JSON.stringify(xhrError.response.data) : '' }\n\nEndpoint: ${endpoint}`, 'error')
                }
            }

            // Always re-throw error
            throw xhrError
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                                GET REQUESTS                                */
    /* -------------------------------------------------------------------------- */

    // Don't bother replacing: Only used on cabinet list, installation detail and task detail. All pages that does not work
    async getTasksV2(taskType, installationId, serviceOrderId, query) {
        try {
            let payload = {
                installationId,
                serviceOrderId,
                query,
            }
            console.log(payload)
            let response = await this.call('get', `/v2/ServiceNow/Suppliers/Supplier/${this.SupplierID}/Tasks/${taskType}`, payload)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTasksV2':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    // TODO: Replace with /api/v3/ProjectTasks
    async getProjectTasksV2All() {
        try {
            let response = await this.call('get', `/v2/ProjectTasks/All/By/${this.SupplierID}`)
            return response.data
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getProjectTasksV2All':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    getProjectTasksV3(options = {}) {
        return new Promise((resolve, reject) => {
            let {
                updatedSince = null, 
                state = null, 
                installationLabel = null, 
                actualStart = null,
                actualEnd = null,
                includeContacts = false, 
            } = options;

            let query = '';

            if (state){
                query += `?state=${state}`;
            }
            if (includeContacts){
                query += query.length ? '&includeContacts=true' : '?includeContacts=true';
            }
            if (installationLabel){
                query += query.length ? `&installation=${installationLabel}` : `?installation=${installationLabel}`;
            }
            if (updatedSince){
                const encodedUpdatedSince = encodeURIComponent(updatedSince);
                query += query.length ? `&updatedSince=${encodedUpdatedSince}` : `?updatedSince=${encodedUpdatedSince}`;
            } 
            if (actualStart){
                const encodedActualStart = encodeURIComponent(actualStart);
                query += query.length ? `&actualStart=${encodedActualStart}` : `?actualStart=${encodedActualStart}`;
            }
            if (actualEnd){
                const encodedActualEnd = encodeURIComponent(actualEnd);
                query += query.length ? `&actualEnd=${encodedActualEnd}` : `?actualEnd=${encodedActualEnd}`;
            }  

            // console.log('getProjectTasksV3', `/v3/ProjectTasks${query}`);
            this.call('get', `/v3/ProjectTasks${query}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(new NetworkError(`Error in receiving data from provider 'PilotBI' in 'getProjectTasksV3':\n${JSON.stringify(error, null, 4)}`));
                });
        });
    }

    // TODO: Replace with /api/v3/TroubleTickets
    async getTroubleTicketsV2(state) {
        let payload = null;
        if (state) {
            payload = {
                state
            }
        }
        try {
            let response = await this.call('get', `/v2/TroubleTickets/All/By/${this.SupplierID}`, payload)
            return response.data
            
        } catch (error) {
            console.error(error)
            throw new NetworkError(`Error in receiving data from provider 'PilotBI' in 'dataGetTroubleTicketsV2:\n${JSON.stringify(error, null, 4)}`)
        }
    }

    getTroubleTicketsV3(options = {}) {
        return new Promise((resolve, reject) => {
            let {
                state = null, 
                installationLabel = null, 
                byUser = false,
                resolvedAt = null,
                includeContacts = false, 
            } = options;

            let query = '';

            if (state){
                query += `?state=${state}`;
            }
            if (includeContacts){
                query += query.length ? '&includeContacts=true' : '?includeContacts=true';
            }
            if (installationLabel){
                query += query.length ? `&installation=${installationLabel}` : `?installation=${installationLabel}`;
            }
            if (byUser){
                query += query.length ? `&byUser=true` : `?byUser=true`;
            } 
            if (resolvedAt) {
                const encodedResolvedAt = encodeURIComponent(resolvedAt);
                query += query.length ? `&resolvedAt=${encodedResolvedAt}` : `?resolvedAt=${encodedResolvedAt}`;
            }

            // console.log('getTroubleTicketsV3', `/v3/TroubleTickets${query}`);
            this.call('get', `/v3/TroubleTickets${query}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(new NetworkError(`Error in receiving data from provider 'PilotBI' in 'getTroubleTicketsV3':\n${JSON.stringify(error, null, 4)}`));
                });
        });
    }

    async getTroubleTicketsByInst(instLabel) {
        // No longer includes historical tickets
        try {
            let allTickets = await this.getTroubleTicketsV2();
            let filteredTickets = allTickets.filter(ticket => ticket.installation.number === instLabel);
            return filteredTickets;
        } catch (error) {
            console.log(error);
            throw new NetworkError(`Error in receiving data from provider 'PilotBI' in 'dataGetTroubleTicketsByInst:'\n${JSON.stringify(error, null, 4)}`);
        }
    }

    /**
     * @param {Date} since 
     * @returns {Object}
     * @throws {NetworkError}
     */
    // TODO: Replace with /api/v3/ProjectTasks using query parameter
    // Note: This will be done in dataAPI and use getProjectTasksV3 with actualEnd query parameter and state query parameter
    async getClosedProjectTasks(since) {
        let sinceString = since.toISOString().split('T')[0]
        try {
            let response = await this.call('get', `/v1/ServiceNow/Suppliers/Supplier/${this.SupplierID}/Tasks/Since/${sinceString}`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetClosedProjectTasks':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    getProjectTask(projectTaskId) {
        return new Promise((resolve, reject) => {
            if (!projectTaskId) {
                reject(new Error('Cannot get projectTask without ID'));
            }
            // console.log('getProjectTask', `/v3/ProjectTasks/${projectTaskId}`);
            this.call('get', `/v3/ProjectTasks/${projectTaskId}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getProjectTask':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    // TODO: Replace with /api/v3/TroubleTickets/{id}
    async getTroubleTicket(troubleTicketId) {
        try {
            let response = await this.call('get', `/v1/ServiceNow/TroubleTickets/TroubleTicket/${troubleTicketId}`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTroubleTicket':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    getTroubleTicketV3(troubleTicketId, errorShouldPopup) {
        return new Promise((resolve, reject) => {
            if (!troubleTicketId) {
                reject(new Error('Cannot get troubleTicket without ID'));
            }
            // console.log('getTroubleTicketV3', `/v3/TroubleTickets/${troubleTicketId}`);
            this.call('get', `/v3/TroubleTickets/${troubleTicketId}`, null, errorShouldPopup)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTroubleTicketV3':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getProjectTaskAttachments(projectTaskId) {
        return new Promise((resolve, reject) => {
            if (!projectTaskId) {
                reject(new Error('Cannot get attachments without projectTaskId'));
            }
            // console.log('getProjectTaskAttachments', `/v3/ProjectTasks/${projectTaskId}/attachments`);
            this.call('get', `/v3/ProjectTasks/${projectTaskId}/attachments`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getProjectTaskAttachments':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getTroubleTicketAttachments(troubleTicketId) {
        return new Promise((resolve, reject) => {
            if (!troubleTicketId) {
                reject(new Error('Cannot get attachments without troubleTicketId'));
            }
            // console.log('getTroubleTicketAttachments', `/v3/TroubleTickets/${troubleTicketId}/attachments`);
            this.call('get', `/v3/TroubleTickets/${troubleTicketId}/attachments`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTroubleTicketAttachments':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    // TODO: Replace with /api/v3/ProjectTasks/{id}/attachments/{id} and /api/v3/TroubleTickets/{id}/attachments/{id}
    async getAttachment(attachmentId) {
        this.AxiosConfig.params = {
            supplierId: this.SupplierID
        }
        try {
            let response = await this.call('get', `/v1/ServiceNow/ProjectTasks/ProjectTask/Attachment/${attachmentId}`)
            delete this.AxiosConfig.params
            return response.data
        }
        catch (xhrError) {
            delete this.AxiosConfig.params
            console.error(xhrError)
            if (xhrError.response?.status == 403) {
                swal('Adgang nægtet','FiberLAN har ikke tilladelse til at åbne denne fil','error')
            } else {
                throw new Error(`Error in receiving data from provider 'Pilotbi' in 'getAttachment':\n${JSON.stringify(xhrError, null, 4)}`)
            }
        }
    }

    getProjectTaskAttachment(projectTaskId, attachment) {
        return new Promise((resolve, reject) => {
            if (!attachment.id || !attachment.name) {
                reject(new Error('Attachment must have both id and name'));
            }
            const encodedName = encodeURIComponent(attachment.name);
            // console.log('getProjectTaskAttachment', `/v3/ProjectTasks/${projectTaskId}/attachments/${attachment.id}?attachmentName=${encodedName}`);
            this.call('get', `/v3/ProjectTasks/${projectTaskId}/attachments/${attachment.id}?attachmentName=${encodedName}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getProjectTaskAttachment':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getTroubleTicketAttachment(troubleTicketId, attachment) {
        return new Promise((resolve, reject) => {
            if (!attachment.id || !attachment.name) {
                reject(new Error('Attachment must have both id and name'));
            }
            const encodedName = encodeURIComponent(attachment.name);
            // console.log('getTroubleTicketAttachment', `/v3/TroubleTickets/${troubleTicketId}/attachments/${attachment.id}?attachmentName=${encodedName}`);
            this.call('get', `/v3/TroubleTickets/${troubleTicketId}/attachments/${attachment.id}?attachmentName=${encodedName}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTroubleTicketAttachment':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getProducts(orderId) {
        return new Promise((resolve, reject) => {
            if (!orderId) {
                reject(new Error('Cannot get products without orderId (sonWinId from serviceOrder)'));
            }
            // console.log('getProducts', `/v1/Sonwin/Products/By/${orderId}`);
            this.call('get', `/v1/Sonwin/Products/By/${orderId}`, null, false)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetProducts':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    // TODO: Deprecate
    async getServiceOrder(serviceOrderId, responseMeta) {
        if (!serviceOrderId) {
            throw new Error('Cannot get serviceOrder without ID')
        }
        try {
            let response = await this.call('get', `/v1/ServiceNow/ServiceOrders/ServiceOrder/${serviceOrderId}`, null, false) //Supress popup for this call, as there is a fallback
            if (responseMeta) responseMeta.http = response
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getServiceOrder':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    // TODO: Replace with /api/v3/ServiceOrders?installation={label}
    async getAllServiceOrdersByInst(instLabel) {
        if (!instLabel) {
            throw new Error('Cannot get serviceOrders without Installation label')
        }
        try {
            let response = await this.call('get', `/v2/ServiceNow/ServiceOrders/all-by-customer-&-installation/By/${this.SupplierID}?installation=${instLabel}`)
            return response
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getAllServiceOrdersByInst':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    getTechnicalFromId(technical, id) {
        return new Promise((resolve, reject) => {
            let technicalId = id;
            technicalId = technicalId.replace(/\s/g, '') //Remove whitespace from string
            if (id.indexOf('-') == -1) technicalId += '-'; //Add a dash if it's missing, assuming the number provided is a HUB number
            let [hubNum, uubNum] = technicalId.split('-'); //Split the technical ID into HUB and UUB numbers
            if (!parseInt(hubNum) || (technical.toLowerCase() == 'uub' && !parseInt(uubNum))) { //If the HUB number is not a number, reject the promise, UUB number only checked if technical is UUB
                console.error(`technical ID '${id}' seems to be formatted incorrectly\nReading as HUB:'${hubNum}' and UUB:'${uubNum}'`);
                reject(new Error(`technical ID '${id}' seems to be formatted incorrectly`));
            } else {
                return this.call('get', `/v1/Technicals/Containers/By/${technicalId}/${technical}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(xhrError => {
                        console.error(xhrError);
                        reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTechnical':\n${JSON.stringify(xhrError, null, 4)}`));
                    });
            }
        });
    }

    getReportFromIdSource(idSource, planType, formatType = 'XLSX') {
        return new Promise((resolve, reject) => {
            if (!idSource || !planType) {
                reject(new Error('idSource and planType are required'));
            }
            this.call('get', `/v1/Technicals/Report/${idSource}/${planType}/${formatType}`)
                .then(response => {
                    resolve(response);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getReport':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getNexelData(instLabel) {
        return new Promise((resolve, reject) => {
            if (!instLabel) {
                reject(new Error('Cannot get Nexel data without installation label'));
            }
            this.call('get', `/v1/Technicals/Nexel/Installation/${instLabel}`)
                .then(response => {
                    resolve(response);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in retrieving Nexel data for inst:${instLabel} from provider 'PilotBI' in 'getNexelData':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getNexelStatus(instLabel) {
        return new Promise((resolve, reject) => {
            if (!instLabel) {
                reject(new Error('Cannot get Nexel status without installation label'));
            }
            this.call('get', `/v1/Technicals/Nexel/Installation/${instLabel}/Status`)
                .then(response => {
                    resolve(response);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in retrieving Nexel status for inst:${instLabel} from provider 'PilotBI' in 'getNexelStatus':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    getOnHoldReasons() {
        return new Promise((resolve, reject) => {
            this.call('get', '/v3/ProjectTasks/OnHold/Reasons')
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getOnHoldReasons':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                POST REQUESTS                               */
    /* -------------------------------------------------------------------------- */

    // TODO: Replace with /api/v3/ProjectTasks/{id}/notes and /api/v3/TroubleTickets/{id}/notes
    async postNote(projectTaskId, noteBody, middleRequestText = 'ProjectTasks/ProjectTask', endRequestText = '/Note') {
        try {
            let response = await this.call('post', `/v1/ServiceNow/${middleRequestText}/${projectTaskId}${endRequestText}`, String('"'+String(noteBody)+'"'))
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'postNote':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    postNoteOnProjectTask(projectTaskId, noteBody) {
        return new Promise((resolve, reject) => {
            if (!projectTaskId || !noteBody) {
                reject(new Error('Cannot post note without projectTaskId and noteBody'));
            }
            // console.log('postNoteOnProjectTask', `/v3/ProjectTasks/${projectTaskId}/notes`);
            const payload = {
                note: noteBody
            }
            this.call('post', `/v3/ProjectTasks/${projectTaskId}/notes`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in posting data to provider 'Pilotbi' in 'postNoteOnProjectTask':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    postNoteOnTroubleTicket(troubleTicketId, noteBody) {
        return new Promise((resolve, reject) => {
            if (!troubleTicketId || !noteBody) {
                reject(new Error('Cannot post note without troubleTicketId and noteBody'));
            }
            // console.log('postNoteOnTroubleTicket', `/v3/TroubleTickets/${troubleTicketId}/notes`);
            const payload = {
                note: noteBody
            }
            this.call('post', `/v3/TroubleTickets/${troubleTicketId}/notes`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in posting data to provider 'Pilotbi' in 'postNoteOnTroubleTicket':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    // TODO: Replace with /api/v3/ProjectTasks/{id}/attachments and /api/v3/TroubleTickets/{id}/attachments
    async postAttachment(projectTaskId, name, type, base64Content, middleRequestText = 'ProjectTasks/ProjectTask', endRequestText = '/Attachment'){
        try {
            let fileObj = {
                name: name,
                type: type,
                base64Content: base64Content
            }
            let response = await this.call('post', `/v1/ServiceNow/${middleRequestText}/${projectTaskId}${endRequestText}`, JSON.stringify(fileObj))
            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'postAttachment':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    postAttachmentOnProjectTask(projectTaskId, name, type, base64Content) {
        return new Promise((resolve, reject) => {
            if (!projectTaskId || !name || !type || !base64Content) {
                reject(new Error('Cannot post attachment without projectTaskId, name, type and base64Content'));
            }
            // console.log('postAttachmentOnProjectTask', `/v3/ProjectTasks/${projectTaskId}/attachments`);
            const payload = {
                name: name,
                type: type,
                base64Content: base64Content
            }
            this.call('post', `/v3/ProjectTasks/${projectTaskId}/attachments`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in posting data to provider 'Pilotbi' in 'postAttachmentOnProjectTask':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    postAttachmentOnTroubleTicket(troubleTicketId, name, type, base64Content) {
        return new Promise((resolve, reject) => {
            if (!troubleTicketId || !name || !type || !base64Content) {
                reject(new Error('Cannot post attachment without troubleTicketId, name, type and base64Content'));
            }
            // console.log('postAttachmentOnTroubleTicket', `/v3/TroubleTickets/${troubleTicketId}/attachments`);
            const payload = {
                name: name,
                type: type,
                base64Content: base64Content
            }
            this.call('post', `/v3/TroubleTickets/${troubleTicketId}/attachments`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in posting data to provider 'Pilotbi' in 'postAttachmentOnTroubleTicket':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                PUT REQUESTS                                */
    /* -------------------------------------------------------------------------- */

    // TODO: Replace with /api/v3/ProjectTasks/{id}
    async updateProjectTask(projectTaskId, state, assignee, connectionDate, onHoldReason, onHoldSubReason, plannedStart, plannedEnd, signalStrengthData, signalStrengthCaTV, speedTestFiberbox, dataportCheckPerformed) {
        let projectTaskObj = {
            state,
            assignee,
            connectionDate,
            onHoldReason,
            onHoldSubReason,
            plannedStart,
            plannedEnd,
        }
        
        let techDataObj = {
            signalStrengthData,
            signalStrengthCaTV,
            speedTestFiberbox,
            dataportCheckPerformed: String(!!dataportCheckPerformed),
        }
        
        try {
            let techResponse
            if (signalStrengthData || signalStrengthCaTV || speedTestFiberbox || dataportCheckPerformed) {
                techResponse = await this.updateTechnicalData(projectTaskId, techDataObj) //Sends PATCH-request to update technical data first
                // console.log(techResponse)
            }
            let response = await this.call('put', `/v2/ProjectTasks/${this.SupplierID}/ProjectTask/${projectTaskId}`, JSON.stringify(projectTaskObj))

            if (techResponse) {
                response.data.configurationItem.technicalData = techResponse.data
            }

            // console.group(`updateProjectTask ${projectTaskId}`)
            // console.log(projectTaskObj, techDataObj)
            // console.log(response.data)
            // console.groupEnd()

            //Compare response to sent update, to verify update went through
            let unMatchedKeys = []
            if (state && state != response.data.state?.value) unMatchedKeys.push('state')
            if (assignee && assignee != response.data.assignee) unMatchedKeys.push('assignee')
            if (connectionDate && connectionDate.substring(0,10) != response.data.connectionDate?.substring(0,10)) unMatchedKeys.push('connectionDate')
            if (onHoldReason && onHoldReason != response.data.state?.reason?.value) unMatchedKeys.push('state.reason')
            if (plannedStart && new Date(plannedStart).toISOString() != new Date(response.data.plannedStart).toISOString()) unMatchedKeys.push('plannedStart')
            if (plannedEnd && new Date(plannedEnd).toISOString() != new Date(response.data.plannedEnd).toISOString()) unMatchedKeys.push('plannedEnd')
            if (signalStrengthData && signalStrengthData != response.data.configurationItem?.technicalData?.signalStrengthData) unMatchedKeys.push('configurationItem.technicalData.signalStrengthData')
            if (signalStrengthCaTV && signalStrengthCaTV != response.data.configurationItem?.technicalData?.signalStrengthCaTV) unMatchedKeys.push('configurationItem.technicalData.signalStrengthCaTV')
            if (speedTestFiberbox && speedTestFiberbox != response.data.configurationItem?.technicalData?.speedTestFiberbox) unMatchedKeys.push('configurationItem.technicalData.speedTestFiberbox')
            if (dataportCheckPerformed && Boolean(JSON.parse(dataportCheckPerformed)) != Boolean(Number(response.data.configurationItem?.technicalData?.portCheckPerformed))) unMatchedKeys.push('configurationItem.technicalData.portCheckPerformed')

            if (unMatchedKeys.length) { //If any of the above checks failed, throw an error
                throw new NetworkError(`The task was updated, but the following field(s) did not update correctly: ${unMatchedKeys.join(', ')}`)
            }

            return response.data
        }
        catch (error) {
            console.error(error)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'dataUpdateProjectTask':\n${error.message}`)
        }
    }

    updateProjectTaskV3(projectTaskId, taskUpdates = {}, errorChecks = {}) {
        return new Promise((resolve, reject) => {
            let {
                state = null, // stringified number
                assignee = null, // string
                connectionDate = null, // string (ISO 8601)
                onHoldReason = null, // string
                onHoldSubReason = null, // string
                plannedStart = null, // string (ISO 8601)
                plannedEnd = null, // string (ISO 8601)
                slaBreachReason = null, // string

                signalStrengthData = null, // string
                signalStrengthCaTV = null, // string
                speedTestFiberbox = null, // string
                dataportCheckPerformed = null, // boolean
            } = taskUpdates;

            let {
                checkState = true,
                checkAssignee = true,
                checkConnectionDate = true,
                checkOnHoldReason = true,
                checkPlannedStart = true,
                checkPlannedEnd = true,
                checkSlaBreachReason = true,
                checkSignalStrengthData = true,
                checkSignalStrengthCaTV = true,
                checkSpeedTestFiberbox = true,
                checkDataportCheckPerformed = true,
            } = errorChecks;
            
            if (!projectTaskId || !state) {
                reject(new Error('Cannot update projectTask without ID and state'));
            }

            let techResponse = null;
            const updateTechDataPromise = new Promise((resolve, reject) => {
                if (signalStrengthData || signalStrengthCaTV || speedTestFiberbox || dataportCheckPerformed) {
                    let techDataObj = {
                        signalStrengthData,
                        signalStrengthCaTV,
                        speedTestFiberbox,
                        dataportCheckPerformed: String(!!dataportCheckPerformed),
                    };
                    this.updateTechnicalData(projectTaskId, techDataObj)
                        .then(response => {
                            techResponse = response;
                            resolve();
                        })
                        .catch(error => {
                            console.error(error);
                            reject(new NetworkError(`Error in updating technical data for provider 'Pilotbi' in 'updateProjectTaskV3':\n${error.message}`));
                        });
                } else {
                    resolve();
                }
            });

            updateTechDataPromise.then(() => {
                let payload = {
                    state,
                }

                if (assignee) { payload.assignee = assignee; }
                if (connectionDate) { payload.connectionDate = connectionDate; }
                if (onHoldReason) { payload.onHoldReason = onHoldReason; }
                if (onHoldSubReason) { payload.onHoldSubReason = onHoldSubReason; }
                if (plannedStart) { payload.plannedStart = this.convertToISO(plannedStart); }
                if (plannedEnd) { payload.plannedEnd = this.convertToISO(plannedEnd); }
                if (slaBreachReason) { payload.slaBreachReason = slaBreachReason; }

                console.log('updateProjectTaskV3', `/v3/ProjectTasks/${projectTaskId}`);
                console.log(payload);
                this.call('put', `/v3/ProjectTasks/${projectTaskId}`, payload)
                    .then(response => {
                        if (techResponse) {
                            response.data.configurationItem.technicalData = techResponse.data
                        }

                        let unMatchedKeys = [];
                        if (checkState && state && state != response.data.state?.value) unMatchedKeys.push('state');
                        if (checkAssignee && assignee && assignee != response.data.assignee) unMatchedKeys.push('assignee');
                        if (checkConnectionDate && connectionDate && response.data.connectionDate && connectionDate.substring(0,10) != response.data.connectionDate?.substring(0,10)) unMatchedKeys.push('connectionDate');
                        if (checkOnHoldReason && onHoldReason && onHoldReason != response.data.state?.reason?.value) unMatchedKeys.push('state.reason');
                        
                        if (checkPlannedStart && plannedStart) {
                            let responsePlannedStart = response.data.plannedStart;
                            const plannedStartAssumedLocal = new Date(responsePlannedStart).toISOString();
                            if (!responsePlannedStart.includes('Z')) { responsePlannedStart += 'Z'; }
                            let plannedStartAssumedZulu = new Date(responsePlannedStart).toISOString();

                            const plannedStartMatchesLocal = new Date(plannedStart).toISOString() === plannedStartAssumedLocal;
                            const plannedStartMatchesZulu = new Date(plannedStart).toISOString() === plannedStartAssumedZulu;
                            
                            if (!plannedStartMatchesLocal && !plannedStartMatchesZulu) {
                                unMatchedKeys.push('plannedStart');
                            }
                        }

                        if (checkPlannedEnd && plannedEnd) {
                            let responsePlannedEnd = response.data.plannedEnd;
                            const plannedEndAssumedLocal = new Date(responsePlannedEnd).toISOString();
                            if (!responsePlannedEnd.includes('Z')) { responsePlannedEnd += 'Z'; }
                            let plannedEndAssumedZulu = new Date(responsePlannedEnd).toISOString();

                            const plannedEndMatchesLocal = new Date(plannedEnd).toISOString() === plannedEndAssumedLocal;
                            const plannedEndMatchesZulu = new Date(plannedEnd).toISOString() === plannedEndAssumedZulu;
                        
                            if (!plannedEndMatchesLocal && !plannedEndMatchesZulu) {
                                unMatchedKeys.push('plannedEnd');
                            }
                        }

                        if (checkSlaBreachReason && slaBreachReason && slaBreachReason != response.data.slaBreachReason) unMatchedKeys.push('slaBreachReason');
                        if (checkSignalStrengthData && signalStrengthData && signalStrengthData != response.data.configurationItem?.technicalData?.signalStrengthData) unMatchedKeys.push('configurationItem.technicalData.signalStrengthData')
                        if (checkSignalStrengthCaTV && signalStrengthCaTV && signalStrengthCaTV != response.data.configurationItem?.technicalData?.signalStrengthCaTV) unMatchedKeys.push('configurationItem.technicalData.signalStrengthCaTV')
                        if (checkSpeedTestFiberbox && speedTestFiberbox && speedTestFiberbox != response.data.configurationItem?.technicalData?.speedTestFiberbox) unMatchedKeys.push('configurationItem.technicalData.speedTestFiberbox')
                        if (checkDataportCheckPerformed && dataportCheckPerformed && Boolean(JSON.parse(dataportCheckPerformed)) != Boolean(response.data.configurationItem?.technicalData?.portCheckPerformed)) unMatchedKeys.push('configurationItem.technicalData.portCheckPerformed')

                        if (unMatchedKeys.length) { //If any of the above checks failed, throw an error
                            reject(new NetworkError(`The task was updated, but the following field(s) did not update correctly: ${unMatchedKeys.join(', ')}`));
                        }

                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(new NetworkError(`Error in posting data to provider 'PilotBI' in 'updateProjectTaskV3':\n${error.message}`));
                    });
            }).catch(error => {
                console.error(error);
                reject(new NetworkError(`Error in updating technical data for provider 'Pilotbi' in 'updateProjectTaskV3':\n${error.message}`));
            });
        });
    }

    // TODO: Replace with /api/v3/TroubleTickets/{id}
    async updateTroubleTicket(troubleTicketId, state, assignee, onHoldReason, plannedStart, plannedEnd) {
        let troubleTicketObj = {
            troubleTicketId,
            state,
            assignee,
            onHoldReason,
            plannedStart,
            plannedEnd,
        }

        try {
            let response = await this.call('put', `/v2/TroubleTickets/${this.SupplierID}/TroubleTicket/${troubleTicketId}`, JSON.stringify(troubleTicketObj))
            console.group(`updateTroubleTicket ${troubleTicketId}`)
            console.log(troubleTicketObj)
            console.log(response)
            console.groupEnd()

            let unMatchedKeys = []
            if (state && state != response.data.state.value) unMatchedKeys.push('state')
            if (assignee && assignee != response.data.assignee) unMatchedKeys.push('assignee')
            if (onHoldReason && onHoldReason != response.data.state.reason.value) unMatchedKeys.push('state.reason.value')
            if (plannedStart && new Date(plannedStart).toISOString() != new Date(response.data.plannedStart).toISOString()) unMatchedKeys.push('plannedStart')
            if (plannedEnd && new Date(plannedEnd).toISOString() != new Date(response.data.plannedEnd).toISOString()) unMatchedKeys.push('plannedEnd')

            if (unMatchedKeys.length) { //If any of the above checks failed, throw an error
                throw new NetworkError(`The ticket was updated, but the following field(s) did not update correctly: ${unMatchedKeys.join(', ')}`)
            }

            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'dataUpdateTroubleTicket':\n${xhrError.message}`)
        }
    }

    updateTroubleTicketV3(troubleTicketId, ticketUpdates = {}) {
        return new Promise((resolve, reject) => {
            let {
                state = null, // stringified number
                assignee = "", // string
                onHoldReason = null, // string
                plannedStart = null, // string (ISO 8601)
                plannedEnd = null, // string (ISO 8601)
            } = ticketUpdates;
            
            if (!troubleTicketId || !state) {
                reject(new Error('Cannot update troubleTicket without ID and state'));
            }

            // let payload = {
            //     state,
            // }

            // if (assignee) { payload.assignee = assignee; }
            // if (onHoldReason) { payload.onHoldReason = onHoldReason; }
            // if (plannedStart) { payload.plannedStart = plannedStart; }
            // if (plannedEnd) { payload.plannedEnd = plannedEnd; }
            let payload = { ...ticketUpdates };
            if (payload.assignee === null) { payload.assignee = ""; }

            // console.log('updateTroubleTicketV3', `/v3/TroubleTickets/${troubleTicketId}`);
            this.call('put', `/v3/TroubleTickets/${troubleTicketId}`, payload)
                .then(response => {
                    let unMatchedKeys = [];
                    if (state && state != response.data.state.value) unMatchedKeys.push('state');
                    if (assignee && assignee != response.data.assignee) unMatchedKeys.push('assignee');
                    if (onHoldReason && onHoldReason != response.data.state.reason.value) unMatchedKeys.push('state.reason.value');
                    let responsePlannedStart = response.data.plannedStart;
                    let responsePlannedEnd = response.data.plannedEnd;
                    if (!responsePlannedStart.includes('Z')) { responsePlannedStart += 'Z'; }
                    if (!responsePlannedEnd.includes('Z')) { responsePlannedEnd += 'Z'; }
                    if (plannedStart && new Date(plannedStart).toISOString() != new Date(responsePlannedStart).toISOString()) unMatchedKeys.push('plannedStart');
                    if (plannedEnd && new Date(plannedEnd).toISOString() != new Date(responsePlannedEnd).toISOString()) unMatchedKeys.push('plannedEnd');

                    if (unMatchedKeys.length) { //If any of the above checks failed, throw an error
                        reject(new NetworkError(`The ticket was updated, but the following field(s) did not update correctly: ${unMatchedKeys.join(', ')}`));
                    }

                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject(new NetworkError(`Error in posting data to provider 'PilotBI' in 'updateTroubleTicketV3':\n${error.message}`));
                });
        });
    }

    /* -------------------------------------------------------------------------- */
    /*                                PATCH REQUESTS                              */
    /* -------------------------------------------------------------------------- */
    
    // TODO: Replace with /api/v3/ProjectTasks/{id}/ConnectionDate/Delete
    async deleteConnectionDate(taskId) {
        try {
            let response = await this.call('patch', `/v1/ServiceNow/ProjectTasks/${taskId}/ConnectionDate/Delete`)
            return response
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in deleting connectionDate in provider 'PilotBI' in 'deleteConectionDate'`)
        }
    }

    deleteConnectionDateV3(projectTaskId) {
        return new Promise((resolve, reject) => {
            if (!projectTaskId) {
                reject(new Error('Cannot delete connectionDate without projectTaskId'));
            }
            // console.log('deleteConnectionDateV3', `/v3/ProjectTasks/${projectTaskId}/ConnectionDate/Delete`);
            this.call('patch', `/v3/ProjectTasks/${projectTaskId}/ConnectionDate/Delete`)
                .then(response => {
                    resolve(response);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in deleting connectionDate in provider 'Pilotbi' in 'deleteConnectionDateV3':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }

    async updateTechnicalData(taskId, payload) {
        try {
            let response = await this.call('patch', `/v1/Technicals/Data/${taskId}`, payload)
            return response
        } catch (error) {
            console.error(error)
        }
    }

    updateProjectTaskStatus(projectTaskId, state, onHoldReason = null, onHoldSubReason = null) {
        return new Promise((resolve, reject) => {
            if (!projectTaskId || !state) {
                reject(new Error('Cannot update projectTask status without ID and state'));
            }
            // console.log('updateProjectTaskStatus', `/v3/ProjectTasks/${projectTaskId}/Status`);
            let payload = {
                state: state,
            }
            if (onHoldReason) { payload.onHoldReason = onHoldReason; }
            if (onHoldSubReason) { payload.onHoldSubReason = onHoldSubReason; }

            this.call('patch', `/v3/ProjectTasks/${projectTaskId}/Status`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(xhrError => {
                    console.error(xhrError);
                    reject(new NetworkError(`Error in updating projectTask status for provider 'Pilotbi' in 'updateProjectTaskStatus':\n${JSON.stringify(xhrError, null, 4)}`));
                });
        });
    }
}
