/**
 * To add a new setting:
 *   1. Add setting to settings array
 *     - Required 
 *       - value: type(any)
 *       - default: type(any)
 *       - dataType: ('string'/'bool')
 *       - type: ('radio'/'checkbox'/'draggable')
 *       - text: type(string) Text to be shown for setting
 *       - appliesTo: type(array[string]) List of pages and tasktype the setting applies to
 *         - 'PT': ProjectTasks
 *         - 'TT': TroubleTickets
 *         - 'only combined': Only when ProjectTasks and TroubleTickets are combined
 *         - 'project list': project list page
 *         - 'booking dashboard': booking page
 *         - 'cabinet list': cabinet list page
 *         - 'installation list': installation list page
 *     - Optional    
 *       - fileName: type(string)
 *       - options: type(array[{text: , value:}]) Optional: actions: {click: , arg:}
 *       - disabledBy: type(string) key of setting to be disabled by
 *       - disabledMode: type(boolean) 
 *       - specialLoad: type(string) name of method to use
 *       - specialSave: type(string) name of method to use
 * 
 *   2. If specialLoad or specialSave options have been used
 *     2.1 Write special load function (naming: key followed by SL)
 *     2.2 Add special load function to specialLoadFunctions()
 *     2.3 Write special save function (naming: key followed by SS)
 *     2.4 Add special save function to specialSaveFunctions()
 * 
 *   3. Add setting to settingGroups to make the setting show up in the change settings modal 
 */

class CachedSettings {
    constructor(){

        this.spaceKeyCount = 0
        this.showKeyCount = 0
        this.projectID = null

        this.allSettings = {
            sortProjectsAscending: {
                value: '0',
                default: '0',
                fileName: 'pl-settings-sort-projecs-ascending',
                dataType: 'string',
                type: 'radio',
                name: 'projects_sort_ascending',
                options: [
                    {text: 'Stigende', value: '0'},
                    {text: 'Faldende', value: '1'}],
                appliesTo: ['PT','TT','project list'],
                title: 'Sortering:'},
            sortCabinetsAscending: {
                value: '0',
                default: '0',
                fileName: 'cl-settings-sort-cabinets-ascending',
                dataType: 'string',
                type: 'radio',
                name: 'cabinets_sort_ascending',
                options: [
                    {text: 'Stigende', value: '0'},
                    {text: 'Faldende', value: '1'}],
                appliesTo: ['PT','TT','cabinet list'],
                title: 'Sortering:'},
            showProjectsAsTiles: {
                value: 'list',
                default: 'list',
                fileName: 'pl-settings-show-project-as-tiles',
                dataType: 'string',
                type: 'radio',
                name: 'show_projects_as_tiles',
                options: [
                    {text: 'Liste', value: 'list'},
                    {text: 'Fliser', value: 'tiles'}],
                appliesTo: ['PT','TT','project list'],
                title: 'Vis projekterne som:'},
            showProjectTypes: {
                value: false,
                default: false,
                fileName: 'pl-settings-show-project-types',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Projekttyper',
                appliesTo: ['PT','TT','project list']},
            showOnlyAssociated: {
                value: false,
                default: false,
                fileName: 'pl-settings-show-only-associated',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Kun Tilknyttede',
                appliesTo: ['PT','TT','project list']},
            showOnlyInProgress: {
                value: false,
                default: false,
                fileName: 'pl-settings-show-only-in-progress',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Kun Igangværende',
                appliesTo: ['PT','TT','project list']},
            showClosedProjects: {
                value: false,
                default: false,
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Afsluttet',
                appliesTo: ['PT','TT','project list']},
            showSearchInVirkplan: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-search-in-virkplan',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Knap For Søgning i Virkplan',
                appliesTo: ['PT','TT','booking dashboard']},
            projectPreferences: {
                value: 'general', 
                default: 'general',
                fileName: 'bp-settings-project-preferences-', 
                dataType: 'string',
                type: 'radio',
                name: 'project_preferences',
                options: [
                    {text: 'Brug generelle indstillinger', value: 'general', action: {click: 'onChangeSettingsPreferences', arg: 'general'}},
                    {text: 'Brug projektspecifikke indstillinger', value: 'projectSpecific', action: {click: 'onChangeSettingsPreferences', arg: 'projectSpecific'}}],
                appliesTo: ['PT','TT','booking dashboard', 'installation list', 'cabinet list']},  
            showMap: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-map',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Kort',
                appliesTo: ['PT','TT','booking dashboard', 'cabinet list']},
            mapUseAutoZoom: {
                value: true,
                default: true,
                fileName: 'bp-settings-auto-zoom',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Auto Zoom',
                disabledBy: 'showMap',
                disabledMode: false,
                appliesTo: ['PT','TT','booking dashboard', 'cabinet list']},      
            bookSortAscending: {
                value: '0',
                default: '0',
                fileName: 'bp-settings-sort-booked-ascending',
                dataType: 'string',
                type: 'radio',
                name: 'booked_sort_ascending',
                options: [
                    {text: 'Stigende', value: '0'},
                    {text: 'Faldende', value: '1'}],
                appliesTo: ['PT','TT','booking dashboard']},
            bookedSelectedOptions: {
                value: [],
                default: [],
                fileName: 'bp-settings-sort-booked-by',
                specialLoad: 'bookedSelectedOptionsSL',
                specialSave: 'bookedSelectedOptionsSS',
                dataType: 'string',
                type: 'draggable',
                appliesTo: ['PT','TT','booking dashboard']},  
            mergeTaskTypes: {
                value: 'seperate', 
                default: 'seperate',
                fileName: 'bp-settings-unbooked-merge', 
                dataType: 'string',
                type: 'radio',
                name: 'merge_trt_prj',
                options: [
                    {text: 'Vis drift- og projekt-opgaver sammen', value: 'merge'},
                    {text: 'Vis drift- og projekt-opgaver separat', value: 'seperate'}],
                appliesTo: ['only combined','booking dashboard', 'installation list']}, // 'cabinet list'
            settingPresets: {
                value: 'preset4', 
                default: 'preset4',
                fileName: 'bp-settings-settings-preset', 
                dataType: 'string',
                type: 'radio',
                name: 'settings_preset',
                options: [
                    {text: 'Brugerdefineret', value: 'preset0', action: {click: 'changeSettingsPreset'}},
                    {text: 'Optimeret til kundebooking', value: 'preset1', action: {click: 'changeSettingsPreset'}},
                    {text: 'Optimeret til konverteringer', value: 'preset2', action: {click: 'changeSettingsPreset'}},
                    {text: 'Optimeret til drift', value: 'preset3', action: {click: 'changeSettingsPreset'}},
                    {text: 'Optimeret til projektkunder', value: 'preset4', action: {click: 'changeSettingsPreset'}},
                    {text: 'Optimeret til blæsning', value: 'preset5', action: {click: 'changeSettingsPreset'}},
                    {text: 'Optimeret til eftertilmeldinger', value: 'preset6', action: {click: 'changeSettingsPreset'}}],
                appliesTo: ['PT','TT','booking dashboard']},
            bookedDuration: {
                value: '7days', 
                default: '7days',
                fileName: 'bp-settings-booked-duration', 
                dataType: 'string',
                type: 'radio',
                name: 'booked_duration',
                options: [
                    {text: 'Aftaler dags dato', value: 'today'},
                    {text: 'Aftaler de næste 7 dage', value: '7days'},
                    {text: 'Aftaler de næste 30 dage', value: '30days'},
                    {text: 'Alle aftaler', value: 'all'}],
                appliesTo: ['PT','TT','booking dashboard']},
            sortUnbookedPTBy:{
                value: 'plannedStart',
                default: 'plannedStart',
                fileName: 'bp-settings-sort-unbooked-pt-by',
                dataType: 'string',
                type: 'radio',
                name: 'unbooked_options_project_tasks',
                options: [],
                appliesTo: ['PT','booking dashboard']},
            sortUnbookedTTBy:{
                value: 'priority.label',
                default: 'priority.label',
                fileName: 'bp-settings-sort-unbooked-tt-by',
                dataType: 'string',
                type: 'radio',
                name: 'unbooked_options_trouble_tickets',
                options: [],
                appliesTo: ['TT','booking dashboard']},
            sortInstsBy: {
                value: '',
                default: '',
                fileName: 'il-sort-insts-by',
                dataType: 'string',
                type: 'radio',
                name: 'inst_list_sorting_options',
                options: [],
                appliesTo: ['PT', 'TT', 'installation list']},
            showUnbookedCard: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-unbooked-card',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ikke Booket',
                appliesTo: ['PT','TT','booking dashboard']},
            setUserAsBccOnBooking: {
                value: true,
                default: true,
                fileName: 'bp-settings-set-user-as-bcc-on-booking',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Sæt mig selv som BCC på automatiske mails',
                appliesTo: ['PT','TT','booking dashboard']},
            checkForMissingAssigneeOnBooking: {
                value: true,
                default: true,
                fileName: 'bp-settings-check-for-missing-assignee-on-booking',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Check for manglende montør ved booking',
                appliesTo: ['PT','TT','booking dashboard']},
            showPendingTasks: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-pending-tasks',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Pending',
                disabledBy: 'showUnbookedCard',
                disabledMode: false,
                appliesTo: ['PT','booking dashboard', 'installation list', 'cabinet list']},
            showOnHoldTasks: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-onhold-tasks',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis On Hold',
                disabledBy: 'showUnbookedCard',
                disabledMode: false,
                appliesTo: ['PT','TT','booking dashboard', 'installation list']},
            showResolved: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-resolved',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Resolved',
                appliesTo: ['TT','booking dashboard']},
            showMoreTrtData: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-more-trt-data',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Kort Beskrivelse på Driftsager',
                appliesTo: ['TT','booking dashboard']},  
            showClosed: {
                value: false,
                default: false,
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Afsluttede',
                appliesTo: ['PT','TT','booking dashboard']},
            showCloseCPETaskShortcut: {
                value: true,
                default: true,
                fileName: 'timelines-settings-show-close-cpe-shortcut',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis genvej til afslutning af dagens opgaver',
                appliesTo: ['PT','TT','booking dashboard']},
            showTaskTypeIcon: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-task-type-icon',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis ikoner for opgavetyper',
                appliesTo: ['PT','TT','booking dashboard','installation list']},
            showInternetServiceProviderIcon: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-internet-service-provider-icon',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis ikoner for ISPer',
                appliesTo: ['PT','TT','booking dashboard','installation list']},
            showCallInAdvanceIcon: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-call-in-advance-icon',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis kun opringningsikon på installationsdatoen',
                appliesTo: ['PT','TT','booking dashboard','installation list']},
            showNavigationIcon: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-navigation-icon',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis ikon for navigation i Google Maps',
                appliesTo: ['PT','TT','booking dashboard']},
            showHubNoOnInst: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-hub-on-patch',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis accesshusnummer på installationer',
                appliesTo: ['PT','TT','booking dashboard']},
            showSubtaskStatus: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-subtask-status',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Delopgavestatus',
                appliesTo: ['PT','booking dashboard', 'installation list']},
            showInVainVisits: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-in-vain-visits',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis antal forgæves besøg',
                appliesTo: ['PT','TT','booking dashboard']},
            showInVainCalls: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-in-vain-calls',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis antal forgæves opkald',
                appliesTo: ['PT','TT','booking dashboard']},
            showInVainOnBooked: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-in-vain-on-booked',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis forgæves besøg/opkald på bookede opgaver',
                appliesTo: ['PT','TT','booking dashboard']},
            showTags: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-tags',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis mærkater',
                appliesTo: ['PT','TT','booking dashboard']},
            showTagsAsIcons: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-tags-as-icons',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis mærkater som ikoner på ikke bookede opgaver',
                appliesTo: ['PT','TT','booking dashboard'],
                disabledBy: 'showTags',
                disabledMode: false},
            showCounters: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-counters',
                dataType: 'boolean',
                updated: false,
                type: 'checkbox',
                text: 'Vis Optællinger',
                appliesTo: ['PT','TT','booking dashboard', 'cabinet list']},
            showWeekNums: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-week-numbers',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ugenumre',
                appliesTo: ['PT','TT','booking dashboard']},
            showCancellationListIcon: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-flex-waiting-list',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis ikon for afbudsliste-kunder',
                appliesTo: ['PT','TT','booking dashboard']},
            showWorker: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-worker',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Montør på Tidsplanen',
                appliesTo: ['PT','TT','booking dashboard']},
            showTaskFirstSeenInActiveInst: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-task-first-seen-in-active-inst',
                dataType: 'boolean',
                type: 'checkbox',
                text: "Vis 'først set i FiberTeam' på opgaverne",
                appliesTo: ['PT','TT','booking dashboard']},
            showSLAInActiveInst: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-sla-in-active-inst',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis SLA på opgaverne',
                appliesTo: ['PT','TT','booking dashboard']},
            highlightOwnAppointments: {
                value: false,
                default: false,
                fileName: 'bp-settings-highlight-own-appointments',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Fremhæv egne aftaler',
                appliesTo: ['PT','TT','booking dashboard']},
            showApptTypeTickets: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-appt-type-tickets',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Driftopgaver',
                appliesTo: ['TT','booking dashboard']},
            showApptTypeInspection: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-appt-type-inspection',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Besigtigelsesopgaver',
                appliesTo: ['PT','booking dashboard']},
            bookingDashboardLayout: {
                value: 'layout0',
                default: 'layout0',
                fileName: 'bp-settings-booking-dashboard-layout',
                dataType: 'string',
                type: 'radio',
                text: 'Booking Dashboard Layout',
                appliesTo: ['PT','booking dashboard']},
            showApptTypeInstallation: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-appt-type-installation',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Installationsopgaver',
                appliesTo: ['PT','booking dashboard']},
            showApptTypeTechnician: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-appt-type-technician',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Udvidet Installationsopgaver',
                appliesTo: ['PT','booking dashboard']},
            showApptTypePatch: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-appt-type-patch',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Patchopgaver',
                appliesTo: ['PT','booking dashboard']},
            showApptTypeKS: {
                value: true,
                default: true,
                fileName: 'bp-settings-show-appt-type-ks',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Kvalitetssikrings-opgaver',
                appliesTo: ['PT','booking dashboard']},
            mapShowBookedApptTypeTickets: {
                value: true,
                default: true,
                fileName: 'bp-settings-map-show-booked-appt-type-tickets',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Bookede Driftopgaver',
                appliesTo: ['TT','booking dashboard']},
            mapShowBookedApptTypeInspection: {
                value: true,
                default: true,
                fileName: 'bp-settings-map-show-booked-appt-type-inspection',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Bookede Besigtigelsesopgaver',
                appliesTo: ['PT','booking dashboard']},
            mapShowBookedApptTypeInstallation: {
                value: true,
                default: true,
                fileName: 'bp-settings-map-show-booked-appt-type-installation',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Bookede Installationsopgaver',
                appliesTo: ['PT','booking dashboard']},
            mapShowBookedApptTypeTechnician: {
                value: true,
                default: true,
                fileName: 'bp-settings-map-show-booked-appt-type-technician',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Bookede Udvidet Installationsopgaver',
                appliesTo: ['PT','booking dashboard']},
            mapShowBookedApptTypePatch: {
                value: true,
                default: true,
                fileName: 'bp-settings-map-show-booked-appt-type-patch',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Bookede Patchopgaver',
                appliesTo: ['PT','booking dashboard']},
            mapShowunbookedApptTypeTickets: {
                value: false,
                default: false,
                fileName: 'bp-settings-map-show-unbooked-appt-type-tickets',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ikke Bookede Driftopgaver',
                // disabledBy: 'showUnbookedCard',
                // disabledMode: false,
                appliesTo: ['TT','booking dashboard'],
                action: {click: 'mapUnbookedSettings'}},
            mapShowUnbookedApptTypeInspection: {
                value: false,
                default: false,
                fileName: 'bp-settings-map-show-unbooked-appt-type-inspection',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ikke Bookede Besigtigelsesopgaver',
                // disabledBy: 'showUnbookedCard',
                // disabledMode: false,
                appliesTo: ['PT','booking dashboard'],
                action: {click: 'mapUnbookedSettings'}},
            mapShowUnbookedApptTypeInstallation: {
                value: false,
                default: false,
                fileName: 'bp-settings-map-show-unbooked-appt-type-installation',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ikke Bookede Installationsopgaver',
                // disabledBy: 'showUnbookedCard',
                // disabledMode: false,
                appliesTo: ['PT','booking dashboard'],
                action: {click: 'mapUnbookedSettings'}},
            mapShowUnbookedApptTypeTechnician: {
                value: false,
                default: false,
                fileName: 'bp-settings-map-show-unbooked-appt-type-technician',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ikke Bookede Udvidet Installationsopgaver',
                // disabledBy: 'showUnbookedCard',
                // disabledMode: false,
                appliesTo: ['PT','booking dashboard'],
                action: {click: 'mapUnbookedSettings'}},
            mapShowUnbookedApptTypePatch: {
                value: false,
                default: false,
                fileName: 'bp-settings-map-show-unbooked-appt-type-patch',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Ikke Bookede Patchopgaver',
                // disabledBy: 'showUnbookedCard',
                // disabledMode: false,
                appliesTo: ['PT','booking dashboard'],
                action: {click: 'mapUnbookedSettings'}},
            showBtnForTaskData: {
                value: false,
                default: false,
                fileName: 'bp-setting-show-btn-for-task.data',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis Knapper For Udvidet Info',
                appliesTo: ['PT','TT','booking dashboard']},
            showNumberBeforeRoad: {
                value: false,
                default: false,
                fileName: 'bp-settings-show-number-before-road',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis husnummer før vejnavn',
                // disabledBy: 'showUnbookedCard',
                disabledMode: false,
                appliesTo: ['PT','TT','booking dashboard']},
            illnessTimeInterval: {
                value: '6mo',
                default: '6mo',
                fileName: 'ip-settings-illness-time-interval',
                dataType: 'string',
                type: 'radio', //TODO: Change to dropdown
                title: 'Tidsinterval:',
                options: [
                    { value: '1w', text: 'Seneste uge' },
                    { value: '1mo', text: 'Seneste måned' },
                    { value: '3mo', text: 'Seneste 3 måneder' },
                    { value: '6mo', text: 'Seneste 6 måneder' },
                    { value: '12mo', text: 'Seneste 12 måneder' }],
                appliesTo: ['sygdom']},
            illnessToIncludeChildsIllness: {
                value: false,
                default: false,
                fileName: 'ip-settings-illness-include-childs-illness',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Inkluder barns sygdom',
                appliesTo: ['sygdom']},
            illnessFitterHourCategories: {
                value: [56],
                default: [56],
                fileName: 'ip-settings-illness-fitter-hour-categories',
                dataType: 'string',
                specialLoad: 'arrayOfNumbersSL',
                specialSave: 'arrayOfNumbersSS',
                type: 'dropdownMultiple',
                title: 'Hvad tælles som sygdom?',
                options: [],
                appliesTo: ['sygdom']},
            illnessNonBreakingHourCategories: {
                value: [56,69,70,75],
                default: [56,69,70,75],
                fileName: 'ip-settings-illness-fitter-hour-categories',
                dataType: 'string',
                specialLoad: 'arrayOfNumbersSL',
                specialSave: 'arrayOfNumbersSS',
                type: 'dropdownMultiple',
                title: 'Hvad bryder ikke en sygeperiode?',
                options: [],
                appliesTo: ['sygdom']},
            sortIllnessWorkersBy: {
                value: 'totalIllnessHours',
                default: 'totalIllnessHours',
                fileName: 'ip-settings-sort-illness-workers-by',
                dataType: 'string',
                type: 'radio',
                title: 'Sortér montører efter:',
                options: [
                    { value: 'totalIllnessHours', text: 'Sygdoms-timer' },
                    { value: 'illnessHoursPercentage', text: '% Sygdoms-timer' },
                    { value: 'totalIllnessDays', text: 'Sygedage' },
                    { value: 'illnessDaysPercentage', text: '% Sygedage' },
                    { value: 'illnessPeriods.length', text: 'Totale Sygeperioder' },
                    { value: 'nonHandledIllnessPeriodsCount', text: 'Ikke-håndterede Sygeperioder' },
                    { value: 'fitterName', text: 'Navn (alfabetisk)' },
                    { value: 'department', text: 'Afdeling'}],
                appliesTo: ['sygdom']},
            showOwnIllness: {
                value: true,
                default: true,
                fileName: 'ip-settings-show-own-illness',
                dataType: 'boolean',
                type: 'checkbox',
                text: 'Vis egen sygdom øverst',
                appliesTo: ['sygdom']},
            //TODO: Add multi-select dropdown setting for illness worker table
        }

        this.settingGroups = [
            {
                accordion: false,
                headerText: 'Projekter',
                settings: ['sortProjectsAscending',this.space(),'showProjectsAsTiles',this.space(),'showProjectTypes','showOnlyAssociated','showOnlyInProgress','showClosedProjects']
            },
            {
                accordion: true,
                headerText: 'Projektpræferencer',
                actions: [{text: 'Gendan standard indstillinger', click: 'resetToStandard'}],
                settings: ['projectPreferences']
            },
            {
                accordion: false,
                headerText: 'Presets',
                actions: [{text: 'Vælg preset', click: 'changeSettingsPreset'}],
                settings: [this.show()]
            },
            {
                accordion: false,
                headerText: 'Sortering',
                settings: ['sortCabinetsAscending']
            },
            {
                accordion: false,
                headerText: 'Kort Indstillinger',
                settings: ['showMap','mapUseAutoZoom']
            },
            // {
            //     accordion: true,
            //     headerText: 'Kort Indstillinger',
            //     settings: ['showMap','mapUseAutoZoom',this.space(),
            //         'mapShowBookedApptTypeTickets','mapShowBookedApptTypeInspection','mapShowBookedApptTypeInstallation','mapShowBookedApptTypeTechnician','mapShowBookedApptTypePatch',this.space(),
            //         'mapShowunbookedApptTypeTickets','mapShowUnbookedApptTypeInspection','mapShowUnbookedApptTypeInstallation','mapShowUnbookedApptTypeTechnician','mapShowUnbookedApptTypePatch']
            // },
            {
                accordion: true,
                headerText: 'System Handlinger',
                settings: ['setUserAsBccOnBooking', 'checkForMissingAssigneeOnBooking']
            },
            {
                accordion: true,
                headerText: 'Sortér bookede efter',
                settings: ['bookSortAscending','bookedSelectedOptions']
            },
            {
                accordion: false,
                headerText: 'Flet drift- og projekt-opgaver',
                settings: ['mergeTaskTypes']
            },
            {
                accordion: true,
                headerText: 'Sortér ikke bookede projektopgaver efter',
                settings: ['sortUnbookedPTBy']
            },
            {
                accordion: true,
                headerText: 'Sortér ikke bookede driftopgaver efter',
                settings: ['sortUnbookedTTBy']
            },
            {
                accordion: true,
                headerText: 'Bookede kundefiltre',
                settings: ['bookedDuration']
            },
            {
                accordion: true,
                headerText: 'Ikke bookede kundefiltre',
                settings: ['showUnbookedCard','showPendingTasks','showOnHoldTasks','showResolved','showClosed',this.space(),'showApptTypeTickets','showApptTypeInspection','showApptTypeInstallation','showApptTypeTechnician','showApptTypePatch','showApptTypeKS']
            },
            {
                accordion: true,
                headerText: 'Booking dashboard ikoner mm.',
                settings: [
                    'showNavigationIcon',
                    'showCloseCPETaskShortcut',
                    'showTaskTypeIcon',
                    'showInternetServiceProviderIcon',
                    'showCancellationListIcon',
                    'showHubNoOnInst',
                    'showSubtaskStatus',
                    'showInVainVisits',
                    'showInVainCalls',
                    'showInVainOnBooked',
                    'showCallInAdvanceIcon',
                    'highlightOwnAppointments',
                    'showCounters',
                    'showWeekNums',
                    'showMoreTrtData',
                    'showWorker',
                    'showNumberBeforeRoad',
                    'showTags',
                    'showTagsAsIcons',
                ]
            },
            {
                accordion: false,
                headerText: 'Sygdomsstatistik',
                settings: ['illnessTimeInterval',this.space(),'sortIllnessWorkersBy',this.space(),'illnessFitterHourCategories',this.space(),'illnessNonBreakingHourCategories',this.space(),'showOwnIllness']
            },
            {
                accordion: true,
                headerText: 'Aktiv installation',
                settings: ['showBtnForTaskData', 'showTaskFirstSeenInActiveInst', 'showSLAInActiveInst']
            },
            {
                accordion: false,
                headerText: 'Diverse',
                settings: ['showSearchInVirkplan']
            },
        ]

        this.loadSavedSettings()
          
    }

    space() {
        let newKey = `space_${this.spaceKeyCount}`
        this.spaceKeyCount += 1
        // if (isNaN(this.spaceKeyCount)) this.spaceKeyCount = 901 //If spaceKeyCount is NaN, select arbitrary high number, to avoid overlap with previous keys
        return newKey
    }

    show() {
        let newKey = `showSettingInModal_${this.showKeyCount}`
        this.showKeyCount += 1
        return newKey
    }

    cloneJSON(obj) {
        if (!obj) return null //Avoid error "undefined is not valid JSON"
        return JSON.parse(JSON.stringify(obj)) //convert obj to string and back to obj
    }

    setOptions(key, options){
        this.allSettings[key].options = this.cloneJSON(options)
    }

    setValue(key, value){
        this.allSettings[key].value = this.cloneJSON(value)
        this.allSettings[key].default = this.cloneJSON(value)
    }

    /**
     * Runs a special load function for a setting if implemented
     * Console logs the setting key if an method was expected, but not implemented
     * @param {string} key - The name of the setting
     * @param {string} cacheResponse - Cache responce from browser cache
     */
    specialLoadFunctions(key, cacheResponse){
        switch(key){
            case 'bookedSelectedOptions':
                this.bookedSelectedOptionsSL(cacheResponse)
            break
            case 'illnessFitterHourCategories':
                this.arrayOfNumbersSL(cacheResponse)
            break
            case 'illnessNonBreakingHourCategories':
                this.arrayOfNumbersSL(cacheResponse)
            break
            default: console.log("A special load method have not been implemented for this setting",key)
        }
    }

    /**
     * Runs a special save function for a setting if implemented
     * Console logs the setting key if an method was expected, but not implemeted
     * @param {string} key - The name of the setting
     * @param {string} fileName - Filename for the setting to save (with or without project ID)
     */
    specialSaveFunctions(key, fileName){
        switch(key){
            case 'bookedSelectedOptions':
                this.bookedSelectedOptionsSS(fileName)
            break
            case 'illnessFitterHourCategories':
                this.arrayOfNumbersSS(fileName)
            break
            case 'illnessNonBreakingHourCategories':
                this.arrayOfNumbersSS(fileName)
            break
            default: console.log("A special save method have not been implemented for this setting",key)
        }
    }

    /**
     * Special load function for 'bookedSelectedOptions'
     * 
     * Rearranges the array of options based on the order specified by the cache responce 
     * and assigns the right rank
     * @param {string} cacheResponse - Cache responce fromn browser cache
     */
    bookedSelectedOptionsSL(cacheResponse){
        let sortingRank = cacheResponse.split(',')
        sortingRank.pop()
        let tmpArr0 = this.cloneJSON(this.allSettings.bookedSelectedOptions.value)
        let tmpArr1 = []
        for (let i in this.allSettings.bookedSelectedOptions.value){
            let index = tmpArr0.findIndex(o =>
                o.value == sortingRank[i])
            tmpArr1.push(tmpArr0[index])
        } 
        this.allSettings.bookedSelectedOptions.value = tmpArr1 
    }

    /**
     * Special save function for 'bookedSelectedOptions'
     * 
     * Converts the array to a comma separated string
     * @param {string} fileName - Filename for the setting to save (with or without project ID)
     */
    bookedSelectedOptionsSS(fileName){
        let tmpStr = ""
        for (let i in this.allSettings.bookedSelectedOptions.value){
            tmpStr = tmpStr + this.allSettings.bookedSelectedOptions.value[i].value + ","
            this.allSettings.bookedSelectedOptions.value[i].rank = i
        }
        localStorage.setItem(fileName, tmpStr)
    }

    arrayOfNumbersSS(fileName){
        let tmpStr = ""
        for (let i in this.allSettings.illnessFitterHourCategories.value){
            tmpStr = tmpStr + this.allSettings.illnessFitterHourCategories.value[i] + ","
        }
        localStorage.setItem(fileName, tmpStr)
    }

    arrayOfNumbersSL(cacheResponse){
        let sortingRank = cacheResponse.split(',')
        sortingRank.pop()
        let tmpArr = []
        for (let i in sortingRank){
            tmpArr.push(parseInt(sortingRank[i]))
        }
        this.allSettings.illnessFitterHourCategories.value = tmpArr
    }

    /**
     * General load function for booleans
     * 
     * Retrieves cahced setting from browser 
     * Adds project ID to filename if specific is true
     * If positive cache responce - converts string to boolean and updates selected setting (value)
     * 
     * Returns true if positive cache responce otherwise false
     * @param {string} key - The name of the setting
     * @param {boolean} specific - True if 'projectPreferences' is projectSpecific
     * @returns {boolean}
     */
    loadBoolean(key, specific = false){
        let successfulFetch = false
        if (typeof(this.allSettings[key]?.fileName) != "undefined"){ // only load if it has a file name
            let tmpFileName = this.allSettings[key].fileName
            if (specific) tmpFileName = tmpFileName + '-' + String(this.projectID)
            let cacheResponse = window.localStorage.getItem(tmpFileName) // get saved setting
            if (cacheResponse){ // if positive response: override default setting
                successfulFetch = true
                if (cacheResponse == "true") this.allSettings[key].value = true
                if (cacheResponse == "false") this.allSettings[key].value = false
            }
        }
        return successfulFetch
    }

    /**
     * General load function for strings
     * 
     * Retrieves cached setting from browser
     * Adds project ID to filename if specific is true
     * If positive cahce responce - updates selected setting (value)
     * Runs special load function if specified (specialLoadSave)
     * @param {string} key - The name of the setting
     * @param {boolean} specific - True if 'projectPreferences' is projectSpecific
     * @returns {boolean}
     */
    loadString(key, specific = false){
        let successfulFetch = false
        if (key == "projectPreferences" && this.projectID != null) specific = true
        if (typeof(this.allSettings[key]?.fileName) != "undefined"){ // only load if it has a file name
            let tmpFileName = this.allSettings[key].fileName
            if (specific) tmpFileName = tmpFileName + '-' + String(this.projectID)
            let cacheResponse = window.localStorage.getItem(tmpFileName)
            if (cacheResponse){ // if positive response: override default setting
                successfulFetch = true
                if (this.allSettings[key]?.specialLoad){
                    this.specialLoadFunctions(key, cacheResponse)
                }
                else
                    this.allSettings[key].value = cacheResponse
            }
        }
        return successfulFetch
    }

    /**
     * Method for loading all settings
     * 
     * Loads 'projectPreferences' setting if a project ID have been provided
     * Loads all settings based on type (boolean/string) and project prefenreces
     * Loads general settings if no project ID have been provided  
     * @returns {boolean} - Always true
     */
    loadSavedSettings(){
        let loadSpecific = false
        if (this.projectID != null){
            this.loadString("projectPreferences")
            if (this.allSettings.projectPreferences.value == "projectSpecific") loadSpecific = true
        }

        for (let key in this.allSettings){
            if (this.allSettings[key].dataType == "boolean") this.loadBoolean(key, loadSpecific)
            if (this.allSettings[key].dataType == "string") this.loadString(key, loadSpecific)
        }

        return true
    }

    /**
     * Method for saving a single setting
     * 
     * Adds project ID to filename if project specific
     * Saves the setting as a string 
     * Runs special save function if specified
     * 
     * Returns true if a filename have been provided otherwise false
     * @param {string} key - The name of the setting
     * @param {boolean} specific - True if 'projectPreferences' is projectSpecific
     * @returns {boolean}
     */
    save(key, specific = false){
        let saveSuccessful = false
        if (key == "projectPreferences" && this.projectID != null) specific = true
        if (typeof(this.allSettings[key]?.fileName) != "undefined"){ // only save if it has a file name
            saveSuccessful = true
            let tmpFileName = this.allSettings[key].fileName
            if (specific) tmpFileName = tmpFileName + '-' + String(this.projectID)
            if (this.allSettings[key]?.specialSave){
                this.specialSaveFunctions(key, tmpFileName)
            }
            else
                localStorage.setItem(tmpFileName, this.allSettings[key].value)
        }
        return saveSuccessful
    }

    /**
     * Method for saving all settings at once
     * 
     * Saved all settings based on project preferences
     * If project specfic and no project ID have been provided the method not save and returns false
     * @returns {boolean}
     */
    saveAll(){
        let successfulSave = true
        let saveProSpecific = false
        if (this.projectID != null){
            if (this.allSettings.projectPreferences.value == "projectSpecific") saveProSpecific = true

            for (let key in this.allSettings){
                this.save(key, saveProSpecific)
            }
        }
        else {
            if (this.allSettings.projectPreferences.value == "general"){
                for (let key in this.allSettings){
                    this.save(key, false)
                }
                successfulSave = true
            }
            else {
                console.log("Settings not saved: No Project ID")
                successfulSave = false
            }
            
        }
        return successfulSave
    }

    /**
     * Method for setting the active project ID
     * 
     * Sets the project ID (clone)
     * Loads all settings based on project ID
     * @param {string} proj
     * @returns {boolean}
     */
    setActiveProject(proj){
        if (proj && proj.id){
            this.projectID = JSON.stringify(proj.id) 
        } else {
            this.projectID = null
        }
        return this.loadSavedSettings()
    }

    /**
     * Method for setting the sorting options for booked appointments
     * 
     * Sets the options on both value and default (clone)
     * @todo Rewrite cloning to be deep cloning
     * @param {Array} options - Array of booked sorting options
     */
    setBookedSortingOptions(options){
        this.allSettings.bookedSelectedOptions.value = [...options]
        this.allSettings.bookedSelectedOptions.default = [...options]
    }

    /**
     * 
     * @param {string} key - The name of the setting
     * @param {any} value - Updated value for selected setting
     */
    set(key, value){
        this.allSettings[key].value = value
    }

    /**
     * Method for resetting all settings to default settings
     * 
     * Overwrites value with default
     * @returns {Array} - Array of all setting keys that have been updated
     */
    resetToStandard(){
        let hasBeenUpdated = []
        for (let key in this.allSettings){
            if (JSON.stringify(this.allSettings[key].value) != JSON.stringify(this.allSettings[key].default)) 
                hasBeenUpdated.push(key)
            
                this.allSettings[key].value = this.allSettings[key].default
        }
        return hasBeenUpdated
    }

    /**
     * Method for getting the value of a setting based on key
     * @param {string} key - The name of the setting
     * @returns {any} - The value of the selected setting
     */
    get(key){
        return this.allSettings[key].value
    }
}

export default CachedSettings