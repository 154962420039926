<template>
    <div>
        <portal to="page-title">
            <sui-button icon="arrow left" @click="up" />
            <span>Accesshus {{hub.Number}} - {{hub.Name}}</span>
        </portal>

        <sui-modal v-model="addNoteModalOpen">
            <sui-modal-header>Tilføj note</sui-modal-header>
            <sui-modal-content>
                <sui-form>
                    <sui-message>Navn og tidsstempel påføres automatisk noten</sui-message>
                    <sui-form-field>
                        <label>Note</label>
                        <textarea v-model="newNoteText"></textarea>
                    </sui-form-field>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" @click.prevent="saveNewNote">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="specialAccessModalOpen">
            <sui-modal-header>Særlige adgangsforhold</sui-modal-header>
            <sui-modal-content>
                <sui-form :loading="editHubInfoFormLoading">
                    <sui-form-field>
                        <sui-checkbox toggle label="Har særlige adgangsforhold" v-model="editHasSpecialAccess"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Beskrivelse af særlige adgangsforhold</label>
                        <textarea v-model="editSpecialAccessDescription"></textarea>
                    </sui-form-field>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" @click.prevent="saveSpecialAccess">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="editHubInfoModalOpen">
            <sui-modal-header>Gundlæggende teknikhus info</sui-modal-header>
            <sui-modal-content>
                <sui-form>
                    <sui-form-fields>
                        <sui-form-field title="Accesshusets nummer kan ikke redigeres efter oprettelse">
                            <label>Nr</label>
                            <input disabled type="number" v-model="hub.Number">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Navn</label>
                            <input type="text" v-model="editHubInfoName">
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field>
                        <label>Fiber ring</label>
                        <input type="text" v-model="editHubInfoFiberring">
                    </sui-form-field>
                    <label>Adresse</label>
                    <sui-form-fields>
                        <sui-form-field>
                            <label>Vej</label>
                            <input type="text" name="Vej" placeholder="Æblehaven" v-model="editHubInfoAddressRoad">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Husnr</label>
                            <input type="number" min="1" max="99999" name="Husnr" placeholder="16" v-model="editHubInfoAddressNumber">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Bogstav</label>
                            <input type="text" name="Bogstav" placeholder="B" v-model="editHubInfoAddressLetter">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Lejlighed</label>
                            <input type="text" name="Lejlighed" placeholder="2. TV" v-model="editHubInfoAddressAppartment">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Postnr</label>
                            <input type="number" min="1000" max="9999" name="Postnr" placeholder="5690" v-model="editHubInfoAddressZipcode">
                        </sui-form-field>
                        <sui-form-field>
                            <label>By</label>
                            <input type="text" name="PostBy" placeholder="Tommerup" v-model="editHubInfoAddressCity">
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields>
                        <sui-button @click.prevent="getCoordinatesFromAddress" :loading="dawaLookupLoading">DAWA opslag</sui-button>
                        <sui-form-field>
                            <label>Breddegrad (Latitude)</label>
                            <input type="number" min="-180" max="180" name="Breddegrad" step="0.00000001" placeholder="55,35568566" v-model="editHubInfoCoordinatesLat">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Længdegrad (Longitude)</label>
                            <input type="number" min="-90" max="90" name="Længdegrad" step="0.00000001" placeholder="10,46281374" v-model="editHubInfoCoordinatesLng">
                        </sui-form-field>
                        <sui-checkbox label="Manuelt tilpasset" v-model="editHubInfoCoordinatesManual"/>
                    </sui-form-fields>
                    <sui-message>
                        <sui-message-header>Kommende feature</sui-message-header>
                        <p>Her kommer en feature hvor teknikhusets placering kan tilrettes på et kort</p>
                    </sui-message>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" @click.prevent="saveEditedHubInfo">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="newTaskModalOpen">
            <sui-modal-header>Ny opgave</sui-modal-header>
            <sui-modal-content>
                <sui-form>
                    <sui-form-field>
                        <label>Kort beskrivelse</label>
                        <input type="text" placeholder="Opgavens 'navn'" v-model="newTaskShortDescription">
                    </sui-form-field>
                    <sui-form-field>
                        <label>Beskrivelse</label>
                        <textarea placeholder="Beskrivelse af opgaven, og dens omfang skrives her..." v-model="newTaskDescription"></textarea>
                    </sui-form-field>
                    <sui-form-fields inline>
                        <sui-form-field>
                            <label>Haster</label>
                            <sui-checkbox toggle></sui-checkbox>
                        </sui-form-field>
                        <sui-form-field inline>
                            <label>Ansvarlig</label>
                            <sui-dropdown
                                placeholder="Ansvarlig"
                                selection
                                direction="upward"
                                :options="usersAsDropdown"
                                v-model="selectedTaskResponsible"
                            />
                        </sui-form-field>
                        <sui-form-field inline>
                            <label>Status</label>
                            <sui-dropdown
                                placeholder="Status"
                                selection
                                direction="upward"
                                :options="taskStateOptions"
                                v-model="selectedTaskState"
                            />
                        </sui-form-field>
                    </sui-form-fields>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" @click.prevent="saveNewTask">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="taskDetailModalOpen">
            <sui-modal-header>Opgave detaljer</sui-modal-header>
            <sui-modal-content v-if="activeTask" scrolling>
                <sui-table striped>
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-header-cell colspan="2">
                                {{activeTask.ShortDescription}}
                            </sui-table-header-cell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell>Beskrivelse</sui-table-cell>
                            <sui-table-cell>{{activeTask.Description}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Haster</sui-table-cell>
                            <sui-table-cell>{{activeTask.Urgent ? 'Ja' : 'Nej'}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Ansvarlig</sui-table-cell>
                            <sui-table-cell>{{activeTask.Responsible}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Status</sui-table-cell>
                            <sui-table-cell>
                                <sui-button size="mini" :color="getTtStateColor(activeTask.State.Value)" @click.stop="taskStateClicked(activeTask)" >{{ TicketState.getValueFromCode(activeTask.State.Value) }}</sui-button>
                                <small><em>Senest ændret {{toUserFriendlyTimestamp(activeTask.State.SetAt)}} af {{activeTask.State.SetBy}}</em></small>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Oprettet</sui-table-cell>
                            <sui-table-cell>{{toUserFriendlyTimestamp(activeTask.CreatedAt)}} af {{activeTask.CreatedBy.Name}}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>
                <sui-table striped v-if="activeTask">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-header-cell>Noter</sui-table-header-cell>
                            <sui-table-header-cell style="text-align: right;">
                                <sui-button @click="openHubtaskNoteModal">Tilføj ny note</sui-button>
                            </sui-table-header-cell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row v-for="note in sortedHubtaskNotes" :key="note.id">
                            <sui-table-cell>
                                <small>{{toUserFriendlyTimestamp(note.CreatedAt)}} - <strong>{{note.CreatedBy.Name}}</strong>:</small>
                                <p>{{note.Body}}</p>
                            </sui-table-cell>
                            <sui-table-cell style="text-align: right;">
                                <sui-button v-if="note.CreatedBy.Email == $parent.user.email" size="mini" negative icon="trash" @click="deleteNote(note)" title="Slet note"></sui-button>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="!activeTask.Notes.length">
                            <sui-table-cell><em>Der er ingen noter på denne opgave</em></sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>
                <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Se rå data
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <pre>
                            {{activeTask}}
                        </pre>
                    </sui-accordion-content>
                </sui-accordion>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="hubTaskNoteModalOpen">
            <sui-modal-header>Ny note til opgave</sui-modal-header>
            <sui-modal-content>
                <sui-form>
                    <sui-message>
                        Navn og tidsstempel påføres automatisk noten
                    </sui-message>
                    <sui-form-field>
                        <textarea v-model="hubTaskNote" placeholder="Skriv din note her..."></textarea>
                    </sui-form-field>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" @click.prevent="saveHubtaskNote">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="hubtaskStateChangeModalOpen">
            <sui-modal-header>Ændre status på opgave</sui-modal-header>
            <sui-modal-content>
                <sui-form>
                    <sui-form-field>
                        <label>Ny status</label>
                        <sui-dropdown
                            placeholder="Status"
                            selection
                            :options="TicketState.DROPDOWN_OPTIONS"
                            v-model="selectedHubtaskState"
                        />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Evt note i forbindelse med statusændring</label>
                        <textarea v-model="hubTaskNote"></textarea>
                    </sui-form-field>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" @click.prevent="saveHubtaskStateChange">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <div>
            <!-- <sui-item-meta v-if="task && configurationItem">{{task.number}} - {{ configurationItem.type }} #{{configurationItem.label}} <span v-if="configurationItem.area"><span v-if="configurationItem.area.sonWinProjectId"> - {{configurationItem.area.name}} ( SonWin projekt {{ configurationItem.area.sonWinProjectId }} ) </span></span></sui-item-meta> -->
            <div class="row">
                <div class="col-sm-3">
                    <DataCard 
                        title="Teknikhus" 
                        v-if="hub" 
                        no-padding 
                        class="half-height"
                        :actions="[{ type: 'button', title: 'Redigér', icon: 'edit', eventName: 'edit-hub-button' }]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <sui-table-body>
                                    <sui-table-row>
                                        <sui-table-cell>Acceshus Nr og Navn</sui-table-cell>
                                        <sui-table-cell>{{ hub.Number }} - {{ hub.Name }}</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Fiber ring</sui-table-cell>
                                        <sui-table-cell v-if="hub.Fiberring">{{ hub.Fiberring }}</sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Ca. Adresse</sui-table-cell>
                                        <sui-table-cell v-if="hub.Address">{{ this.formatAddress(hub.Address, false) }}</sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Koordinater<br>(Lat/Long)</sui-table-cell>
                                        <sui-table-cell v-if="hub.Coordinates">{{ hub.Coordinates.Lat }} /<br>{{ hub.Coordinates.Lng }}</sui-table-cell>
                                        <sui-table-cell v-else><em>Kunne ikke læse koordinater</em></sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-3">
                    <DataCard 
                        title="Særlige adgangsforhold"
                        class="half-height"
                        :actions="[{ type: 'button', title: 'Redigér', icon: 'edit', eventName: 'edit-special-access-button' }]"
                    >
                        <div v-if="hub.SpecialAccess && hub.SpecialAccess.Bool">
                            <p>{{hub.SpecialAccess.Description}}</p>
                            <p><small>Senest opdateret af {{hub.SpecialAccess.UpdatedBy ? hub.SpecialAccess.UpdatedBy : 'ukendt bruger'}} {{toUserFriendlyTimestamp(hub.SpecialAccess.Updated)}}</small></p>
                        </div>
                        <div v-else>
                            <em>Da der ikke er noteret særlige adgangsforhold kan du forvente at have adgang med enten teknikhus-nøglen eller bygge-nøglen fra EnergiFyn</em>
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-3">
                    <DataCard 
                        title="Kundefiber" 
                        no-padding 
                        class="half-height"
                        :actions="[
                            !this.editInstFiber ? { type: 'button', title: 'Redigér', icon: 'edit', eventName: 'edit-installation-fiber-button' } : { type: 'button', title: 'Gem', icon: 'save', eventName: 'save-installation-fiber-button' }
                        ]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <sui-table-body v-if="hub.InstallationFiber">
                                    <sui-table-row>
                                        <sui-table-cell>ODF Type</sui-table-cell>
                                        <sui-table-cell v-if="hub.InstallationFiber.ODFType && !editInstFiber">{{ hub.InstallationFiber.ODFType }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editInstFiber">
                                            <sui-input mini type="text" v-model="editInstFiberODF"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Porte per tray</sui-table-cell>
                                        <sui-table-cell v-if="hub.InstallationFiber.PortsPerTray && !editInstFiber">{{ hub.InstallationFiber.PortsPerTray }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editInstFiber">
                                            <sui-input mini type="number" min="1" max="999" v-model="editInstFiberPorts"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Connector</sui-table-cell>
                                        <sui-table-cell v-if="hub.InstallationFiber.Connector && !editInstFiber">{{ hub.InstallationFiber.Connector }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editInstFiber">
                                            <sui-input mini type="text" v-model="editInstFiberConnector"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Splidsemetode</sui-table-cell>
                                        <sui-table-cell v-if="hub.InstallationFiber.SpliceMethod && !editInstFiber">{{ hub.InstallationFiber.SpliceMethod }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editInstFiber">
                                            <sui-input mini type="text" v-model="editInstFiberSplice"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Patchkabel længder</sui-table-cell>
                                        <sui-table-cell v-if="hub.InstallationFiber.PatchCableLength && !editInstFiber">{{ hub.InstallationFiber.PatchCableLength }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editInstFiber">
                                            <sui-input mini type="text" v-model="editInstFiberPatchLength"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                                <sui-table-body v-else>
                                    <sui-table-row>
                                        <sui-table-cell>
                                            <em>Kunne ikke finde data om kundefiber</em>
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-3">
                    <DataCard 
                        title="Backbone" 
                        no-padding 
                        class="half-height"
                        :actions="[
                            !this.editBBFiber ? { type: 'button', title: 'Redigér', icon: 'edit', eventName: 'edit-backbone-fiber-button' } : { type: 'button', title: 'Gem', icon: 'save', eventName: 'save-backbone-fiber-button' }
                        ]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <sui-table-body v-if="hub.Backbone || editBBFiber">
                                    <sui-table-row>
                                        <sui-table-cell>ODF Type</sui-table-cell>
                                        <sui-table-cell v-if="hub.Backbone && hub.Backbone.ODFType && !editBBFiber">{{ hub.Backbone.ODFType }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editBBFiber">
                                            <sui-input mini type="text" v-model="editBBFiberODF"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Porte per tray</sui-table-cell>
                                        <sui-table-cell v-if="hub.Backbone && hub.Backbone.PortsPerTray && !editBBFiber">{{ hub.Backbone.PortsPerTray }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editBBFiber">
                                            <sui-input mini type="number" min="1" max="999" v-model="editBBFiberPorts"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Connector</sui-table-cell>
                                        <sui-table-cell v-if="hub.Backbone && hub.Backbone.Connector && !editBBFiber">{{ hub.Backbone.Connector }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editBBFiber">
                                            <sui-input mini type="text" v-model="editBBFiberConnector"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                                <sui-table-body v-else>
                                    <sui-table-row>
                                        <sui-table-cell>
                                            <em>Kunne ikke finde data om backbone fiber</em>
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </div>
                    </DataCard>
                    <DataCard 
                        title="CATV" 
                        no-padding 
                        class="half-height"
                        :actions="[
                            !editCATV ? { type: 'button', title: 'Redigér', icon: 'edit', eventName: 'edit-catv-button' } : { type: 'button', title: 'Gem', icon: 'save', eventName: 'save-catv-button' }
                        ]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <sui-table-body>
                                    <sui-table-row>
                                        <sui-table-cell>Har CATV</sui-table-cell>
                                        <sui-table-cell v-if="!editCATV">{{ hub.CATV ? 'Ja' : 'Nej' }}</sui-table-cell>
                                        <sui-table-cell v-else>
                                            <sui-checkbox toggle v-model="editCATVBool"/>
                                        </sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Har Forenings-TV</sui-table-cell>
                                        <sui-table-cell v-if="!editCATV">{{ hub.AssociationTV ? 'Ja' : 'Nej' }}</sui-table-cell>
                                        <sui-table-cell v-else>
                                            <sui-checkbox toggle v-model="editAssociationTV"/>
                                        </sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row>
                                        <sui-table-cell>Klar til CATV</sui-table-cell>
                                        <sui-table-cell v-if="hub.CATVReadyODF && !editCATV">{{ hub.CATVReadyODF }}</sui-table-cell>
                                        <sui-table-cell v-else-if="editCATV">
                                            <sui-input mini type="text" v-model="editCATVReady"/>
                                        </sui-table-cell>
                                        <sui-table-cell v-else>-</sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <DataCard
                        title="Opgaver" 
                        no-padding
                        class="half-height"
                        :actions="[{ type: 'button', title: 'Tilføj opgave', icon: 'plus', eventName: 'add-task-button' }]"
                    >
                        <sui-table v-if="tasks.length" striped selectable>
                            <sui-table-row v-for="task in tasks" :key="task.id" @click="hubTaskClicked(task)">
                                <sui-table-cell>{{task.ShortDescription}}</sui-table-cell>
                                <sui-table-cell>
                                    <sui-button size="mini" :color="getTtStateColor(task.State.Value)" @click.stop="taskStateClicked(task)" >{{ TicketState.getValueFromCode(task.State.Value) }}</sui-button>
                                </sui-table-cell>
                            </sui-table-row>
                        </sui-table>
                        <sui-table v-else><sui-table-row><sui-table-cell>
                            <em>Der er tilsyneladende ingen opgaver på dette teknikhus</em>
                        </sui-table-cell></sui-table-row></sui-table>
                    </DataCard>
                </div>
                <div class="col-sm-3">
                    <DataCard 
                        title="Noter" 
                        no-padding 
                        class="half-height" 
                        :actions="[{ type: 'button', title: 'Tilføj ny note', icon: 'plus', eventName: 'add-note-button' }]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <sui-table-body>
                                    <sui-table-row v-for="note in sortedNotes" :key="note.id">
                                        <sui-table-cell v-if="note">
                                            <p><strong>{{note.CreatedBy}}</strong><span> - {{toUserFriendlyTimestamp(note.CreatedAt)}}</span></p>
                                            <p>{{note.Body}}</p>
                                        </sui-table-cell>
                                    </sui-table-row>
                                    <sui-table-row v-if="!hub.Notes || !hub.Notes.length">
                                        <sui-table-cell>
                                            <em>Der er tilsyneladende ingen noter på dette teknikhus</em>
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-3">
                    <DataCard 
                        title="Splidse-planer"
                        no-padding 
                        class="half-height"
                    >
                        <div class="scrollable">
                            <splice-reports-table
                                :id="String(hub.Number)"
                            />
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-3">
                    <DataCard no-padding class="fixed-half-height map-card">
                        <!-- <div class="map" ref="map">
                            <MapMarker
                                v-if="hub.Coordinates"
                                :key="`m-${String(hub.id)}`"
                                :id="String(hub.Number)"
                                :lat="parseFloat(hub.Coordinates.Lat)"
                                :lng="parseFloat(hub.Coordinates.Lng)"
                                :title="`${hub.Number} - ${hub.Name} (${hub.Fiberring})`"
                                :matchSearch="true"
                                :clusterGroup="getClusterGroup()"
                                eventTriggerName="listItemClicked"
                            />
                        </div> -->
                        <OKAPIMap
                            :mapMarkers="mapMarkers"
                            :clickable="false"
                        />
                    </DataCard>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">        
                    <!-- <sui-accordion exclusive styled>
                        <sui-accordion-title>
                            <sui-icon name="dropdown" />
                            Se rå data
                        </sui-accordion-title>
                        <sui-accordion-content>
                            <pre>
                                {{task}}
                            </pre>
                        </sui-accordion-content>
                    </sui-accordion> -->
                </div>
                <div class="col-sm-6">
                    <!-- <sui-accordion exclusive styled>
                        <sui-accordion-title>
                            <sui-icon name="dropdown" />
                            Se rå serviceOrder data
                        </sui-accordion-title>
                        <sui-accordion-content>
                            <pre>
                                {{serviceOrder}}
                            </pre>
                        </sui-accordion-content>
                    </sui-accordion> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import { db, firestore } from '../../firebase'
import EventBus from '../../EventBus'
// import TableLoader from '../../components/TableLoader'
// import { MarkerClusterer } from '@googlemaps/markerclusterer'
// import { Maps, MapMarkerImages } from '../../lib/maps'
import OKAPIMap from '@/components/Maps/OKAPImap.vue'
import swal from 'sweetalert'
import TicketState from '../../lib/Enums/TicketState'
import SpliceReportsTable from '../../components/Project/SpliceReportsTable.vue'

export default {
    name: 'HubDetailView',
    mixins: [Mixin, DateMixin, /* Maps, */ DataAPI],
    enums: [TicketState],
    components: {
        // TableLoader,
        SpliceReportsTable,
        OKAPIMap,
    },

    data() {
        return {
            hub: {},
            map: null,
            mapMarkers: {},
            clusterGroups: {
                'activeTasks': {
                    icon: 'fas fa-check-square',
                    color: '#c3ad00',
                    name: 'Yellow',
                    cluster: null,
                    title: 'Der er aktive opgaver'
                },
                'default': {
                    icon: 'fas fa-home',
                    color: '#666666',
                    name: 'Grey',
                    cluster: null,
                    title: ''
                }
            },
            addNoteModalOpen: false,
            newNoteText: null,
            specialAccessModalOpen: false,

            editHasSpecialAccess: false,
            editSpecialAccessDescription: null,

            editHubInfoModalOpen: false, 
            editHubInfoFormLoading: false,

            editHubInfoName: null,
            editHubInfoFiberring: null,
            editHubInfoAddressRoad: null,
            editHubInfoAddressNumber: null,
            editHubInfoAddressLetter: null,
            editHubInfoAddressAppartment: null,
            editHubInfoAddressZipcode: null,
            editHubInfoAddressCity: null,
            editHubInfoCoordinatesLat: null,
            editHubInfoCoordinatesLng: null,
            editHubInfoCoordinatesManual: null,

            dawaLookupLoading: false,

            editInstFiber: false,

            editInstFiberODF: null,
            editInstFiberPorts: null,
            editInstFiberConnector: null,
            editInstFiberSplice: null,
            editInstFiberPatchLength: null,

            editBBFiber: false,

            editBBFiberODF: null,
            editBBFiberPorts: null,
            editBBFiberConnector: null,

            editCATV: false,

            editCATVBool: null,
            editAssociationTV: null,
            editCATVReady: null,

            tasks: [{}],
            activeTask: null,

            taskDetailModalOpen: false,

            newTaskModalOpen: false,
            taskStateOptions: TicketState.DROPDOWN_OPTIONS,

            newTaskShortDescription: null,
            newTaskDescription: null,
            newTaskUrgent: false,
            selectedTaskResponsible: null,
            selectedTaskState: TicketState.NEW,

            users: [],

            TicketState: TicketState,

            hubTaskNoteModalOpen: false,
            hubTaskNote: null,

            hubtaskStateChangeModalOpen: false,
            selectedHubtaskState: null,
        }
    },

    computed: {
        hubId() {
            return this.$route.params.hubid
        },
        sortedNotes() {
            let unsortedNotes = this.hub.Notes
            if (!unsortedNotes) return []
            return unsortedNotes.sort((a, b) => {return a.CreatedAt > b.CreatedAt ? -1 : 1})
        },
        usersAsDropdown() {
            let outputArray = []
            for (let i in this.users) {
                let user = this.users[i]
                outputArray.push({value: user.Email, text: user.Name})
            }
            return outputArray
        },
        sortedHubtaskNotes() {
            if (!this.activeTask) return []
            let notes = [...this.activeTask.Notes]
            notes.sort((a,b) => {return a.CreatedAt > b.CreatedAt ? -1 : 1})
            return notes
        }
    },

    beforeMount() {
        this.$bind('hub', db.collection('HUBs').doc(this.hubId))
        this.$bind('tasks', db.collection(`HUBs/${this.hubId}/HubTasks`))
        this.$bind('users', db.collection('Users'))
    },

    mounted() {
        EventBus.$on('edit-hub-button-click', this.editHubInfo.bind(this))
        EventBus.$on('edit-special-access-button-click', this.openSpecialAccessModal.bind(this))
        EventBus.$on('edit-installation-fiber-button-click', this.editInstallationFiber.bind(this))
        EventBus.$on('save-installation-fiber-button-click', this.saveInstallationFiber.bind(this))
        EventBus.$on('edit-backbone-fiber-button-click', this.editBackboneFiber.bind(this))
        EventBus.$on('save-backbone-fiber-button-click', this.saveBackboneFiber.bind(this))
        EventBus.$on('edit-catv-button-click', this.startEditCATV.bind(this))
        EventBus.$on('save-catv-button-click', this.saveCATV.bind(this))
        EventBus.$on('add-task-button-click', this.openNewTaskModal.bind(this))
        EventBus.$on('add-note-button-click', this.openNoteModal.bind(this))
    },

    methods: {
        statusClicked() {
            this.stateModalOpen = true
        },
        async initMap() {
            let typeString = `hub-active`//Active HUB marker
            this.mapMarkers[this.hubId] = {
                key: `mapMarker-${this.hubId}`,
                id: this.hubId,
                type: typeString, //TODO: Find correct marker for hub, based on tasks etc
                title: this.formatAddress(this.hub.Address, false),
                coordinates: {
                    lat: parseFloat(this.hub.Coordinates.Lat),
                    lng: parseFloat(this.hub.Coordinates.Lng),
                },
                address: this.formatAddress(this.hub.Address, false),
            }
            await this.sleep(500)
            EventBus.$emit('okapi-map-refresh')
            EventBus.$emit('okapi-map-zoom-to-coords', this.mapMarkers[this.hubId].coordinates)
        },
        openNoteModal() {
            this.addNoteModalOpen = true
        },
        saveNewNote() {
            let now = new Date()
            let noteObj = {
                Body: this.newNoteText,
                CreatedAt: this.formatMachineTimestamp(now),
                CreatedBy: this.$parent.user.displayName,
            }
            db.collection('HUBs').doc(this.hubId).update({
                Notes: firestore.FieldValue.arrayUnion(noteObj) //Add note to array of notes in firebase
            })
        },
        openSpecialAccessModal() {
            this.editHasSpecialAccess = this.hub.SpecialAccess?.Bool
            this.editSpecialAccessDescription = this.hub.SpecialAccess ? this.hub.SpecialAccess.Description : null
            this.specialAccessModalOpen = true
        },
        async saveSpecialAccess() {
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveSpecialAccess', isActive: true})
            this.specialAccessModalLoading = true
            let now = new Date()
            let specialAccessObj = {
                Bool: this.editHasSpecialAccess,
                Description: this.editSpecialAccessDescription,
                Updated: this.formatMachineTimestamp(now),
                UpdatedBy: this.$parent.user.displayName
            }
            await db.collection('HUBs').doc(this.hubId).update({'SpecialAccess': specialAccessObj}).catch((error) => {
                this.specialAccessModalLoading = false
                swal('Fejl',`Oplysningerne om særlige adgangsforhold kunne ikke gemmes, pga følgende fejl:\n${error}`,'error')
            })
            this.specialAccessModalLoading = false 
            swal('Gemt','Oplysningerne om særlige adgangsforhold er nu gemt, og er synlig på siden med det samme', 'success')
            this.specialAccessModalOpen = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveSpecialAccess', isActive: false})
        },
        editHubInfo() {
            //Fill the form with the current data
            this.editHubInfoName = this.hub.Name
            this.editHubInfoFiberring = this.hub.Fiberring
            this.editHubInfoAddressRoad = this.hub.Address?.road
            this.editHubInfoAddressNumber = this.hub.Address?.number
            this.editHubInfoAddressLetter = this.hub.Address?.letter
            this.editHubInfoAddressAppartment = this.hub.Address?.appartment
            this.editHubInfoAddressZipcode = this.hub.Address?.zipcode
            this.editHubInfoAddressCity = this.hub.Address?.city
            this.editHubInfoCoordinatesLat = this.hub.Coordinates?.Lat
            this.editHubInfoCoordinatesLng = this.hub.Coordinates?.Lng
            this.editHubInfoCoordinatesManual = this.hub.Coordinates?.ManuallySet

            //Open the modal
            this.editHubInfoModalOpen = true
        },
        async getCoordinatesFromAddress(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_getCoordinatesFromAddress', isActive: true})
            let address = {
                road: this.editHubInfoAddressRoad,
                number: this.editHubInfoAddressNumber,
                letter: this.editHubInfoAddressLetter,
                zipcode: this.editHubInfoAddressZipcode
            }
            this.dawaLookupLoading = true
            try{
                let coords = await this.externalGetGeoCoordinatesFromAddress(address)
                this.editHubInfoCoordinatesLat = coords.lat
                this.editHubInfoCoordinatesLng = coords.lng
                this.editHubInfoCoordinatesManual = false
                this.dawaLookupLoading = false
                EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_getCoordinatesFromAddress', isActive: false})
                return coords
            }
            catch(err){
                console.error(err)
                this.dawaLookupLoading = false
                EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_getCoordinatesFromAddress', isActive: false})
                return false
            }
        },
        async saveEditedHubInfo(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveEditedHubInfo', isActive: true})
            this.editHubInfoFormLoading = true
            let updateObj = {
                Name: this.editHubInfoName,
                Fiberring: this.editHubInfoFiberring,
                Address: {
                    appartment: String(this.editHubInfoAddressAppartment),
                    city: this.editHubInfoAddressCity,
                    letter: this.editHubInfoAddressLetter,
                    number: String(this.editHubInfoAddressNumber),
                    road: this.editHubInfoAddressRoad,
                    zipcode: String(this.editHubInfoAddressZipcode),
                },
                Coordinates: {
                    Lat: Number(this.editHubInfoCoordinatesLat),
                    Lng: Number(this.editHubInfoCoordinatesLng),
                    ManuallySet: this.editHubInfoCoordinatesManual,
                },
            }
            await db.collection('HUBs').doc(this.hubId).update(updateObj)
            this.editHubInfoFormLoading = false
            this.editHubInfoModalOpen = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveEditedHubInfo', isActive: false})
        },
        editInstallationFiber(){
            this.editInstFiberODF = this.hub?.InstallationFiber?.ODFType
            this.editInstFiberPorts = this.hub?.InstallationFiber?.PortsPerTray
            this.editInstFiberConnector = this.hub?.InstallationFiber?.Connector
            this.editInstFiberSplice = this.hub?.InstallationFiber?.SpliceMethod
            this.editInstFiberPatchLength = this.hub?.InstallationFiber?.PatchCableLength
            this.editInstFiber = true
        },
        async saveInstallationFiber(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveInstallationFiber', isActive: true})

            if (
                !this.editInstFiberODF ||
                !this.editInstFiberPorts ||
                !this.editInstFiberConnector ||
                !this.editInstFiberSplice ||
                !this.editInstFiberPatchLength
            ) {
                swal('Ugyldig data','For at kunne gemme skal alle felter udfyldes med gyldig data\nSkriv evt "N/A" hvis der mangler information','error')
                EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveInstallationFiber', isActive: false})
                return false
            }

            let updateObj = {
                InstallationFiber: {
                    ODFType: this.editInstFiberODF,
                    PortsPerTray: Number(this.editInstFiberPorts),
                    Connector: this.editInstFiberConnector,
                    SpliceMethod: this.editInstFiberSplice,
                    PatchCableLength: String(this.editInstFiberPatchLength)
                },
            }
            if (updateObj != {InstallationFiber: this.hub.InstallationFiber}){ //This if-statement will always run, should be corrected
                console.log('saving installation fiber')
                await db.collection('HUBs').doc(this.hubId).update(updateObj)
            }
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveInstallationFiber', isActive: false})
            this.editInstFiber = false
        },
        editBackboneFiber(){
            this.editBBFiberODF = this.hub?.Backbone?.ODFType
            this.editBBFiberPorts = this.hub?.Backbone?.PortsPerTray
            this.editBBFiberConnector = this.hub?.Backbone?.Connector
            this.editBBFiber = true
        },
        async saveBackboneFiber(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveBackboneFiber', isActive: true})

            if (
                !this.editBBFiberODF ||
                !this.editBBFiberPorts ||
                !this.editBBFiberConnector
            ) {
                swal('Ugyldig data','For at kunne gemme skal alle felter udfyldes med gyldig data\nSkriv evt "N/A" hvis der mangler information','error')
                EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveBackboneFiber', isActive: false})
                return false
            }

            let updateObj = {
                Backbone: {
                    ODFType: this.editBBFiberODF,
                    PortsPerTray: Number(this.editBBFiberPorts),
                    Connector: this.editBBFiberConnector,
                },
            }
            if (updateObj != {Backbone: this.hub?.Backbone}){ //This if-statement will always run, should be corrected
                console.log('saving backbone fiber')
                await db.collection('HUBs').doc(this.hubId).update(updateObj)
            }
            this.editBBFiber = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveBackboneFiber', isActive: false})
        },
        startEditCATV(){
            this.editCATVBool = this.hub.CATV
            this.editAssociationTV = this.hub.AssociationTV
            this.editCATVReady = this.hub.CATVReadyODF
            this.editCATV = true
        },
        async saveCATV(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveCATV', isActive: true})
            let updateObj = {
                CATV: this.editCATVBool,
                AssociationTV: this.editAssociationTV,
                CATVReadyODF: this.editCATVReady,
            }
            //Implement check here to see if anything actually changed
            console.log('saving CATV info')
            await db.collection('HUBs').doc(this.hubId).update(updateObj)
            this.editCATV = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveCATV', isActive: false})
        },
        openNewTaskModal(){
            this.newTaskModalOpen = true
        },
        clearNewTaskModal() {
            this.newTaskShortDescription = null
            this.newTaskDescription = null
            this.newTaskUrgent = false
            this.selectedTaskResponsible = null
            this.selectedTaskState = null
        },
        async saveNewTask() {
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveNewTask', isActive: true})
            this.newTaskModalLoading = true
            let nowString = this.formatMachineTimestamp(new Date())
            let taskObj = {
                ShortDescription: this.newTaskShortDescription,
                Description: this.newTaskDescription,
                HubNum: this.hub.Number,
                Urgent: this.newTaskUrgent,
                Responsible: this.selectedTaskResponsible,
                State: {
                    Value: this.selectedTaskState,
                    SetBy: this.$parent.user.displayName,
                    SetAt: nowString
                },
                CreatedAt: nowString,
                CreatedBy: {
                    Name: this.$parent.user.displayName,
                    Email: this.$parent.user.email,
                },
                Notes: [],
            }
            await db.collection(`HUBs/${this.hubId}/HubTasks`).doc(this.activeTask?.id).set(taskObj).catch((err) => {
                console.error(err)
                swal('Fejl ved oprettelse af opgave', `Kunne ikke gemme opgaven i databasen, pga følgende fejl: ${err}`, 'error')
                this.newTaskModalLoading = false
                EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveNewTask', isActive: false})
                return false
            })
            this.newTaskModalOpen = false
            this.clearNewTaskModal()
            this.newTaskModalLoading = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveNewTask', isActive: false})
        },
        hubTaskClicked(task){
            this.activeTask = task
            this.taskDetailModalOpen = true
        },
        taskStateClicked(task){
            this.activeTask = task
            this.hubtaskStateChangeModalOpen = true
        },
        openHubtaskNoteModal(){
            this.hubTaskNoteModalOpen = true
        },
        async saveHubtaskNote(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveHubtaskNote', isActive: true})
            let noteObj = {
                Body: this.hubTaskNote,
                CreatedAt: this.formatMachineTimestamp(new Date()),
                CreatedBy: {
                    Name: this.$parent.user.displayName,
                    Email: this.$parent.user.email,
                },
            }
            await db.collection(`HUBs/${this.hubId}/HubTasks`).doc(this.activeTask.id).update({
                Notes: firestore.FieldValue.arrayUnion(noteObj)
            })
            this.activeTask = this.tasks.find((task) => task.id == this.activeTask.id)
            this.hubTaskNoteModalOpen = false
            this.hubTaskNote = null
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveHubtaskNote', isActive: false})
        },
        async deleteNote(note){
            swal({
                title: 'Vil du virkeligt slette denne note?',
                text: 'Slettede noter kan ikke findes igen',
                icon: 'warning',
                buttons: ['Anuller sletning', 'Slet note'],
                dangerMode: true
            }).then(async (shouldDelete) => {
                if (shouldDelete) {
                    EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_deleteNote', isActive: true})
                    await db.collection(`HUBs/${this.hubId}/HubTasks`).doc(this.activeTask.id).update({
                        Notes: firestore.FieldValue.arrayRemove(note)
                    })
                    this.activeTask = this.tasks.find((task) => task.id == this.activeTask.id)
                    EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_deleteNote', isActive: false})
                }
            })
        },
        async saveHubtaskStateChange(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveHubtaskStateChange', isActive: true})
            if (this.hubTaskNote) {
                this.saveHubtaskNote()
            }
            let stateObj = {
                SetAt: this.formatMachineTimestamp(new Date()),
                SetBy: this.$parent.user.displayName,
                Value: this.selectedHubtaskState,
            }
            await db.collection(`HUBs/${this.hubId}/HubTasks`).doc(this.activeTask.id).update({
                State: stateObj
            })
            this.activeTask = this.tasks.find((task) => task.id == this.activeTask.id)
            this.hubTaskNote = null
            this.selectedHubtaskState = null
            this.hubtaskStateChangeModalOpen = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/Detail_saveHubtaskStateChange', isActive: false})
        },
        hubTasksOnHub(){
            return this.tasks.filter((task) => {
                return task.State.Value < TicketState.RESOLVED
            })
        },
        getClusterGroup(){
            // console.log(this.hubTasksOnHub(hub.Number).length ? 'activeTasks' : 'default')
            return this.hubTasksOnHub()?.length ? 'activeTasks' : 'default'
        }
    },

    watch: {
        hub: {
            immediate: false,
            handler(){this.initMap()}
        }
    }
}
</script>
<style scoped>
    .half-height .scrollable {
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        max-height: calc(50vh - 157px); /*Hack to keep scrollable divs (and sui-table's) inside half-height cards*/
    }

    .full-height .scrollable {
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        max-height: calc(100vh - 252px); /*Hack to keep scrollable divs (and sui-table's) inside full-height cards*/
    }

    .scrollable{
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
    }

    .full-height {
        height: calc(100vh - 185px);
    }

    .half-height {
        height: auto;
        max-height: calc(50vh - 100px);
    }

    .fixed-half-height {
        height: calc(50vh - 110px);
    }

    .card-body.p-0 .table tbody>tr>td:first-of-type {
        padding-left: 0.7rem;
    }

    table.selectable tr:hover td {
        cursor: pointer;
    }

    .note-body {
    white-space: break-spaces;
    }

    .centered{
        text-align: center;
    }

    .imageAttachment {
    min-width: 35%;
    max-width: 100%;
    max-height: 600px;
}
</style>