<template>
    <div>
        <sui-modal
            size="small" 
            id="transferUnitWorkModal"
            :open="isOpen" 
            v-on:clickAwayModal="clickAway"
        >
            <sui-modal-header>
                Flyt eller kopier enhedsarbejde til et andet projekt
            </sui-modal-header>

            <sui-modal-content scrolling style="max-height: 70svh;">
                <span v-if="unitWork && unitWork.ConfigurationItem.Label"> {{ `Installationsnummer: ${unitWork.ConfigurationItem.Label}` }} </span><br>
                <span v-if="unitWork && unitWork.ConfigurationItem.Address"> {{ `Adresse: ${unitWork.ConfigurationItem.Address}` }} </span>

                <sui-form>
                    <div class="form-field-spacing-header" v-if="projects.length"> 
                        <sui-form-field>
                            <label> Vælg projekt </label>
                            <sui-dropdown
                                id="chooseProject"
                                selection
                                search
                                :options="projectOptions"
                                placeholder="Vælg projekt"
                                v-model="targetProject"
                                @input="$forceUpdate()"
                            ></sui-dropdown>
                        </sui-form-field>
                    </div>

                    <div class="form-field-spacing-header">
                        <sui-form-field>
                            <label>Flyt eller kopier enhedsarbejde</label>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <sui-checkbox radio
                                label="Flyt enhedsarbejde"
                                name="moveMode"
                                value="move"
                                v-model="moveMode"
                            />
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <sui-checkbox radio
                                label="Kopier enhedsarbejde"
                                name="moveMode"
                                value="copy"
                                v-model="moveMode"
                            />
                        </sui-form-field>
                    </div>

                    <div class="form-field-spacing-header">
                        <sui-form-field>
                            <label>Kun dette stykke enhedsarbejde eller al enhedsarbejde på installationen</label>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <sui-checkbox radio
                                label="Kun dette enhedsarbejde"
                                name="selection"
                                value="this"
                                v-model="selection"
                            />
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <sui-checkbox radio
                                label="Al enhedsarbejde på installationen"
                                name="selection"
                                value="all"
                                v-model="selection"
                            />
                        </sui-form-field>
                    </div>

                </sui-form>

                <span> {{ progressBarHeader }} </span>

                <div v-if="transferStarted">
                    <sui-progress state="active" indicating :percent="progressPercent"/>
                    <label> {{ progressBarLabel }} </label>
                </div>
            </sui-modal-content>

            <sui-modal-actions>
                <sui-button 
                    @click="clickAway"
                >
                    Annuller
                </sui-button>
                <sui-button 
                    primary 
                    :disabled="submitDisabled"
                    @click="submit"
                >
                    OK
                </sui-button>
            </sui-modal-actions>

        </sui-modal>
    </div>
</template>
<script>

import swal from "sweetalert"
import EventBus from '@/EventBus.js'
import { TransferMixin } from '../../lib/helpers/transferMixin.js'
import ProjectStatus from '../../lib/Enums/ProjectStatus'
import { db } from '../../firebase'

export default {
    props: {
        isOpen: Boolean,
        unitWork: Object,
    },

    mixins: [
        TransferMixin,
    ],

    enums: {
        ProjectStatus,
    },

    data() {
        return {
            moveMode: 'move',
            selection: 'all',

            targetProject: null,
            currentProject: null,
            projects: [],

            progressPercent: 0,
            progressBarLabel: '',
            transferStarted: false,
        }
    },

    computed: {
        progressBarHeader(){
            let label = '';
            if (this.selection === 'this') {
                label = 'Enhedsarbejdet'
            } else {
                label = 'Alt enhedsarbejde på installationen'
            }

            if (this.moveMode === 'move') {
                label += ' vil blive fjernet fra dette projekt og tilføjet til det valgte projekt.'
            } else {
                label += ' vil blive kopieret til det valgte projekt.'
            }
            return label
        },

        projectOptions(){
            let options = []
            options = this.projects
            .filter(project => project.id !== this.currentProject)
            .filter(project => project.Status != ProjectStatus.CLOSED)
            .map(project => {
                return {
                    text: project.Name,
                    value: project.id,
                }
            })
            return options
        },

        submitDisabled(){
            if (this.targetProject === null) {
                return true
            }

            return false
        },
    },

    methods: {
        clickAway(){
            this.moveMode = 'move',
            this.selection = 'all',

            this.targetProject = null,
            this.currentProject = null,
            this.projects = [],

            this.progressPercent = 0,
            this.progressBarLabel = '',
            this.transferStarted = false,
            this.$emit('close');
        },

        async submit(){

            EventBus.$emit('function-activity', {functionName: 'UnitWorkTransferModal_submit', isActive: true})
            let swalTitle = `Er du sikker på du vil ${this.moveMode === 'move' ? 'flytte' : 'kopiere'} enhedsarbejde?`;
            let shouldContinue = await swal({
                title: swalTitle,
                text: 'Dette kan være en meget krævende handling!',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            })

            if (!shouldContinue) {
                EventBus.$emit('function-activity', {functionName: 'UnitWorkTransferModal_submit', isActive: false})
                return
            }

            let data = {
                moveMode: this.moveMode,
                selection: this.selection,
                targetProjectId: this.targetProject,
                currentProjectId: this.currentProject,
                unitWork: this.unitWork,
            }
            await this.transferUnitWork(data);
            EventBus.$emit('function-activity', {functionName: 'UnitWorkTransferModal_submit', isActive: false})
            this.clickAway();
        },

        async getProjects(){
            EventBus.$emit('function-activity', {functionName: 'UnitWorkTransferModal_getProjects', isActive: true})
            if (this.projects.length > 0) {
                EventBus.$emit('function-activity', {functionName: 'UnitWorkTransferModal_getProjects', isActive: false})
                return
            }

            await this.$bind('projects', db.collection('Projects').orderBy('Name', 'asc'))
            EventBus.$emit('function-activity', {functionName: 'UnitWorkTransferModal_getProjects', isActive: false})
        },
    },

    mounted() {
        EventBus.$on('unitwork-transfer-progress-percentage', (payload) => {
            this.progressPercent = payload;
            this.progressBarLabel = `${payload}% data overført`;
        })

        EventBus.$on('unitwork-transfer-started', () => {
            this.transferStarted = true;
        })

    },

    watch: {
        async isOpen(val){
            if (val) {
                this.currentProject = this.$store.state.activeProject.id
                await this.getProjects()
            }
        }
    }
}
</script>

<style scoped>
.form-field-spacing {
    margin-top: 3px;
    margin-bottom: 3px;
}
.form-field-spacing-header {
    margin-top: 20px;
    margin-bottom: 3px;
}
</style>