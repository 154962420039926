<template>
  <div>
        <file-upload-modal 
            v-if="fileUploadModalOpen"
            :isModalOpen="fileUploadModalOpen"
            :path="currentPath"
        />
        <sui-table striped single-line selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell :colspan="showUpdated ? '2' : '1'">
                        <sui-button v-if="rootPath != currentPath" size="mini" icon="left arrow" @click="backArrowClicked"></sui-button>
                        <small>{{currentPath.substr(rootPath.length)}}</small>
                    </sui-table-header-cell>
                    <sui-table-header-cell>
                        <div style="text-align: right;">
                            <sui-button v-if="userHasPriviledge" size="mini" icon="upload" @click="openFileUploadModal">Upload fil</sui-button>
                        </div>
                    </sui-table-header-cell>
                </sui-table-row>
                <sui-table-row>
                    <sui-table-header-cell>Filnavn</sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-if="showUpdated">Opdateret</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="!tableLoading">
                <template v-if="nesting">
                    <sui-table-row v-for="folder in foldersArr" :key="folder.fullPath" @click="folderClicked(folder)">
                    <sui-table-cell><i class="fas fa-folder"></i> {{folder.name}}</sui-table-cell>
                    <sui-table-cell></sui-table-cell>
                    <sui-table-cell v-if="showUpdated"></sui-table-cell>
                </sui-table-row>
                </template>
                <template>
                    <sui-table-row v-for="file in filesArr" :key="file.fullPath" @click="fileClicked(file)">
                        <sui-table-cell>{{file.name}}</sui-table-cell>
                        <sui-table-cell>{{formatBytes(file.size)}}</sui-table-cell>
                        <sui-table-cell v-if="showUpdated">{{toUserFriendlyTimestamp(file.updated)}}</sui-table-cell>
                    </sui-table-row>
                </template>
            </sui-table-body>
            <sui-table-body v-else>
                <table-loader/>
            </sui-table-body>
        </sui-table>
  </div>
</template>

<script>
import EventBus from '../../EventBus'
import { storageRef } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import TableLoader from '../TableLoader.vue'
import FileUploadModal from './FileUploadModal.vue'

export default {
  components: { TableLoader, FileUploadModal },
    name: 'FileExplorer',
    mixins: [Mixin, DateMixin],

    // components: {
    // },

    // enums: {
    // },

    props: {
        rootPath: {
            type: String,
            required: true,
        },
        nesting: {
            type: Boolean,
            required: false,
            default: true,
        },
        showUpdated: {
            type: Boolean,
            required: false,
            default: false,
        },
        userHasPriviledge: {
            type: Boolean,
            required: false,
            default: true,
        }
    },

    data() {
        return {
            currentPath: this.rootPath,
            filesRef: storageRef.child(this.rootPath),
            filesArr: [],
            foldersArr: [],
            tableLoading: false,
            fileUploadModalOpen: false,
        }
    },

    computed: {
        user(){
            return this.$parent.$parent.$parent.user
        },
    },

    methods: {
        async listFiles(){
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_listFiles', isActive: true})
            this.filesArr = []
            console.log("files ref", this.filesRef)
            let result = await this.filesRef.listAll()
            console.log("result list all", result)
            if (this.nesting){
                this.foldersArr = result.prefixes
            }
            for(let i in result.items){
                const itemRef = result.items[i]
                itemRef.getMetadata().then((metadata) => {
                    this.$set(this.filesArr, this.filesArr.length, metadata)
                })
            }
            console.log("filesArr", this.filesArr)
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_listFiles', isActive: false})
        },
        async fileClicked(file){
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_fileClicked', isActive: true})
            let fileRef = this.filesRef.child(file.name)
            let downloadURL = await fileRef.getDownloadURL()
            console.log("downloadURL", downloadURL)
            EventBus.$emit('link-to-external-url',downloadURL)
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_fileClicked', isActive: false})
        },
        async folderClicked(folder){
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_folderClicked', isActive: true})
            this.tableLoading = true
            this.currentPath = `${this.currentPath}/${folder.name}`
            this.filesRef = storageRef.child(this.currentPath),
            await this.listFiles()
            // console.log(folder)
            this.tableLoading = false
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_folderClicked', isActive: false})
        },
        async backArrowClicked(){
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_backArrowClicked', isActive: true})
            this.tableLoading = true

            let currentPathArray = this.currentPath.split('/')
            currentPathArray.pop()
            this.currentPath = currentPathArray.join('/')

            // this.currentPath = this.currentPath.substr(0, this.currentPath.indexOf('/')) //only works for single-layer nesting

            this.filesRef = storageRef.child(this.currentPath),
            await this.listFiles()
            this.tableLoading = false
            EventBus.$emit('function-activity', {functionName: 'FileExplorer_backArrowClicked', isActive: false})
        },
        openFileUploadModal(){
            this.fileUploadModalOpen = true
        },
        closeFileUploadModal(){
            this.fileUploadModalOpen = false
        },
        fileDidUpload(){
            this.listFiles()
            this.closeFileUploadModal()
        },
    },
 
    // },

    mounted(){
        this.listFiles()
        EventBus.$on('file-upload-modal-closing', this.closeFileUploadModal.bind(this))
        EventBus.$on('file-uploaded', this.fileDidUpload.bind(this))
    }

    //Beforemount, mounted o lign funktioner her

}
</script>

<style scoped>

    .full-height {
        height: calc(100vh - 185px);
    }

    .table tbody {
        overflow: auto;
    }

    .table tr td {
        cursor: pointer;
    }

    .scollable {
        overflow: auto;
    }

</style>