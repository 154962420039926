<template>
  <div>
      <DataCard no-padding title="Filer">
          <div class="scrollable">
                <file-explorer
                    rootPath="global"
                    :showUpdated="true"
                    :userHasPriviledge="this.$parent.isAdmin"
                />
          </div>
      </DataCard>
  </div>
</template>

<script>
import FileExplorer from '../../components/Storage/FileExplorer.vue'
import { Mixin } from '../../lib/Mixins/mixin'

export default {
    components: { FileExplorer },
    name: 'FilesList',
    mixins: [Mixin],

    // components: {
    // },

    // enums: {
    // },

    data() {
        return {
        }
    },

    // computed: {
    // },

    methods: {
    },
 
    // },

    mounted(){
    }

    //Beforemount, mounted o lign funktioner her

}
</script>

<style scoped>

    .full-height {
        height: calc(100vh - 185px);
    }

    .table tbody {
        overflow: auto;
    }

    .table tr td {
        cursor: pointer;
    }

    .scollable {
        overflow: auto;
    }

</style>