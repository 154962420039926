<template>
    <sui-modal 
        id="editTechDataModal"
        :open="isOpen" 
        v-on:clickAwayModal="clickAway"
        v-if="task"
    >
        <sui-modal-header>
            <span v-if="task.configurationItem"> Teknisk data for {{formatAddress(task.configurationItem.address, false)}} </span>
        </sui-modal-header>
        <sui-modal-content scrolling style="max-height: 70vh">
            <div>
                <sui-form :loading="formLoading">
                    <sui-form-fields>
                        <sui-form-field width="six" title="Udfyld signalstyrkemåling på data-fiber i dBm">
                            <label>Signalstyrke Data</label>
                            <sui-input v-model="signalStrengthData"/>
                        </sui-form-field>
                        <sui-form-field width="six" :title="`Udfyld signalstyrkemåling på TV-fiber i dBm\n(udfyldes med '-' hvis der ikke er signal på TV-fiber)`">
                            <label>Signalstyrke TV</label>
                            <sui-input v-model="signalStrengthCaTV"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields v-if="isOpen">
                        <sui-form-field width="five">
                            <sui-table striped v-if="task.configurationItem">
                                <nexel-status :instLabel="task.configurationItem.label"/>
                            </sui-table>
                        </sui-form-field>
                        <sui-form-field width="six">
                            <div class="scrollable" style="max-height: 30vh">
                                <sui-table striped v-if="task.configurationItem">
                                    <nexel-data :instLabel="task.configurationItem.label"/>
                                </sui-table>
                            </div>
                        </sui-form-field>
                        <sui-form-field width="five" title="Kryds af at du har tjekket på fqpn at porten er korrekt">
                            <p>
                                <sui-checkbox style="padding-top: 3px; margin-right: 5px;" v-model="dataportCheckPerformed"/>
                                <span class="fake-label" @click="dataportCheckPerformed = !dataportCheckPerformed">Port tjekket <span v-if="technicalData">({{technicalData.asrName}} - {{technicalData.asrPort}})</span></span>
                            </p>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field title="Resultat af evt udført hastighedstest noteres her">
                        <label>Hastighedstest</label>
                        <sui-input v-model="speedTestFiberbox"/>
                    </sui-form-field>
                </sui-form>
            </div>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                negative
                @click="clickAway"
            >
                Luk
            </sui-button>
            <sui-button
                positive
                @click="saveChanges"
            >
                Gem
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import { DataAPI } from '../../lib/DataAPI'
import { Mixin } from '../../lib/Mixins/mixin'
import NexelStatus from '@/components/Project/NexelStatus'
import NexelData from '@/components/Project/NexelData'

export default {
    mixins: [Mixin, DataAPI, EventBus],

    components: {
        NexelStatus,
        NexelData,
    },

    props: {
        task: Object,
        isOpen: Boolean,
        installationLabel: String,
    },

    data() {
        return {
            signalStrengthData: "",
            signalStrengthCaTV: "",
            dataportCheckPerformed: false,
            speedTestFiberbox: "",

            formLoading: false,

        }
    },

    computed: {
        technicalData() {
            // console.log('re-computing property "technicalData"')

            if (!this.task?.configurationItem) return {}
            return this.task.configurationItem.technicalData
        }
    },

    methods: {
        clickAway() {
            EventBus.$emit('edit-tech-data-modal-closing')
        },

        prePopulateForm() {
            this.formLoading = true
            this.signalStrengthData = this.technicalData?.signalStrengthData
            this.signalStrengthCaTV = this.technicalData?.signalStrengthCaTV
            this.dataportCheckPerformed = this.stringToBool(this.technicalData?.portCheckPerformed)
            this.speedTestFiberbox = this.technicalData?.speedTestFiberbox
            this.formLoading = false
        },

        async saveChanges() {
            EventBus.$emit('function-activity', {functionName: 'EditTechDataModal_saveChanges', isActive: true})
            this.formLoading = true
            //(projectTaskId, state, assignee, connectionDate, onHoldReason, onHoldSubreason, plannedStart, plannedEnd, signalStrengthData, signalStrengthCaTV, speedTestFiberbox, dataportCheckPerformed)
            try {
                await this.dataUpdateProjectTask(this.task.id, this.task, {
                    // state: this.task.state?.value,
                    // assignee: this.task.assignee,
                    // connectionDate: this.task.connectionDate,
                    // onHoldReason: this.task.state.reason.value,
                    // onHoldSubReason: this.task.state.reason.subReason.value,
                    // plannedStart: this.task.plannedStart,
                    // plannedEnd: this.task.plannedEnd,
                    signalStrengthData: this.signalStrengthData,
                    signalStrengthCaTV: this.signalStrengthCaTV,
                    speedTestFiberbox: this.speedTestFiberbox,
                    dataportCheckPerformed: this.dataportCheckPerformed,
                })
                // } this.task.state?.value, this.task.assignee, this.task.connectionDate, this.task.state.reason.value, this.task.state.reason.subReason.value, this.task.plannedStart, this.task.plannedEnd, this.signalStrengthData, this.signalStrengthCaTV, this.speedTestFiberbox, this.dataportCheckPerformed)
                EventBus.$emit('tech-data-changed')
                this.formLoading = false
                this.clickAway()
                EventBus.$emit('function-activity', {functionName: 'EditTechDataModal_saveChanges', isActive: false})
            }
            catch(e){
                console.error(e)
                //Error pop-up-message?
                this.formLoading = false
                EventBus.$emit('function-activity', {functionName: 'EditTechDataModal_saveChanges', isActive: false})
            }

        },
    },

    watch: {
        isOpen: {
            immediate: true,
            handler(isOpen){
                if(isOpen){
                    this.prePopulateForm()
                }
            }
        }
    }

}
</script>
<style scoped>
.fake-label {
    cursor: pointer;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
}
</style>