<template>
    <sui-modal
        id="removeAppointmentModal"
        :open="isOpen"
        v-on:clickAwayModal="clickAway"
        v-if="installation && !(installation.appointment && installation.appointment.AppointmentType && installation.appointment.AppointmentType == 'Custom')"
        size="small"
    >

        <sui-modal-header>
            Aflys aftale <span v-if="appointment && appointment.AppointmentType">({{AppointmentType.translate(appointment.AppointmentType)}}) </span>for {{ formatAddress(this.getConfigurationInstallation(installation).address) }}
        </sui-modal-header>
        <sui-modal-content>
            <sui-form
                :loading="isLoading"
            >

                <sui-form-field v-if="appointment && appointment.TimeWindowString">
                    <label>Aflyser aftale om {{AppointmentType.translate(appointment.AppointmentType)}} {{toUserFriendlyDate(appointment.TimeWindowString.substring(0,10))}} kl {{appointment.TimeWindowString.substring(11, 16)}}-{{appointment.TimeWindowString.substring(17, 22)}}<span v-if="appointment.Worker && appointment.Worker.Email"> ({{appointment.Worker.Email.substring(0, appointment.Worker.Email.indexOf('@')).toUpperCase()}})</span></label>
                </sui-form-field>

                <sui-form-field>
                    <label>Beskriv venligst begrundelsen for aflysnig af aftale:</label>
                    <textarea placeholder="Beskriv venligst begrundelsen for aflysnig af aftalen her..." v-model="reason" rows="3"></textarea>
                </sui-form-field>

                <sui-form-fields inline>
                    <sui-form-field>
                        <label>Sæt samtidigt opgaven 'On Hold'</label>
                        <sui-checkbox toggle v-model="setOnHold"/>
                    </sui-form-field>
                    <sui-form-field :disabled="!setOnHold" :required="setOnHold">
                        <label>On hold årsag</label>
                        <sui-dropdown
                            placeholder="On Hold Reason"
                            selection
                            v-model="onHoldReason"
                            :options="onHoldReasonOptions"/>
                    </sui-form-field>
                    <sui-form-field v-if="appointmentType != AppointmentType.TICKET" :disabled="!setOnHold" :required="setOnHold">
                        <label>On Hold Underkategori</label>
                        <sui-dropdown
                            placeholder="On Hold Subreason"
                            selection
                            v-model="onHoldSubreason"
                            :options="onHoldSubreasonOptions"/>
                    </sui-form-field>
                </sui-form-fields>
                <small v-if="onHoldSubreason"><em>{{onHoldSubreasonOptions.find(o => o.value == onHoldSubreason).title}}</em></small>

                <div class="ui message info">
                    <div class="row">
                        <div class="col-sm-6">
                            <sui-form-field>
                                <label>Send besked om aflysnig til kunde:</label>
                                <sui-checkbox toggle v-model="notify" />
                            </sui-form-field>
                        </div>
                        <div class="col-sm-6">
                            <template v-if="appointment && appointment.Contacts">
                                Kontaktoplysninger:
                                <ul>
                                    <template v-for="(mobile, index) in appointment.Contacts.Mobiles">
                                        <li :key="`m-${index}`">{{ mobile }}</li>
                                    </template>
                                    <template v-for="(email, index) in appointment.Contacts.Emails">
                                        <li :key="`e-${index}`">{{ email }}</li>
                                    </template>
                                </ul>
                                <p v-if="!appointment.Contacts.Mobiles.length && !appointment.Contacts.Emails.length">
                                    <em>Ingen kontaktoplysninger fundet.</em>
                                </p>
                            </template>
                        </div>
                    </div>
                </div>

            </sui-form>
        </sui-modal-content>

        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                content="Annuller"
                @click="clickAway"
            />
            <sui-button
                negative
                size="small"
                content="Aflys aftale"
                @click="removeAppointment"
            />
        </sui-modal-actions>

    </sui-modal>
</template>
<script>
import swal from 'sweetalert'
import EventBus from '../../EventBus'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import TaskCode from '../../lib/Enums/TaskCode'
import { mapGetters } from 'vuex'
import AppointmentType from '../../lib/Enums/AppointmentType'
import EmailTemplate from '../../lib/Enums/EmailTemplate'
import SMSTemplate from '../../lib/Enums/SMSTemplate'
import OnHoldReason from '../../lib/Enums/OnHoldReason'
import { analytics } from '../../firebase'

export default {
    name: 'RemoveAppointmentModal',

    mixins: [Mixin, DateMixin, DataAPI],

    enums: {
        TaskCode,
        AppointmentType,
    },

    props: {
        installation: Object,
        appointmentIdProp: String,
        isOpen: Boolean,
    },

    data() {
        return {
            isLoading: false,
            setOnHold: false,
            onHoldReason: null,
            onHoldSubreason: null,
            onHoldReasonOptions: OnHoldReason.DROPDOWN_OPTIONS,
            onHoldSubreasonOptions: [],
            notify: false,
            reason: '',
            appointment: null
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject',
            user: 'userProfile'
        }),

        appointmentType() {
            console.log('re-computing property "appointmentType"')
            if (!this.appointment) return null
            if (this.appointment.AppointmentType) return this.appointment.AppointmentType
            if (this.installation.tasks[0].number.substr(0,3) == 'TRT') return AppointmentType.TICKET
            return AppointmentType.INSTALLATION
            // return (this.installation.tasks[0].number.substr(0,3) == 'TRT') ? AppointmentType.TICKET : AppointmentType.INSTALLATION
        },
    },

    methods: {
        clickAway() {
            EventBus.$emit('remove-appointment-modal-closing')
        },

        async removeAppointment() {
            EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: true})

            if (!this.reason && this.appointment.Confirmed) {
                swal('Udfyld begrundelse', 'Du skal udfylde en begrundelse for sletning af aftalen.', 'warning')
                EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
                return
            }

            if (this.setOnHold && !this.onHoldReason) {
                swal("Vælg 'on hold reason'", "For at kunne sætte opgaven 'on hold' kræves at du vælger en 'on hold reason'", 'warning')
                EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
                return
            }
            if (this.setOnHold && !this.onHoldSubreason && this.appointmentType != AppointmentType.TICKET) {
                swal("Vælg 'on hold reason'", "For at kunne sætte opgaven 'on hold' kræves at du vælger en 'on hold reason'", 'warning')
                EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
                return
            }

            this.isLoading = true

            try {

                console.log(`starting deletion for appointment of type '${this.appointmentType}'`)
                const appointmentTasksModel = AppointmentType.AppointmentTasks[this.appointmentType]
                if (!appointmentTasksModel) {
                    console.error('Appointment type not recognized: '+this.appointmentType)
                    EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
                    throw new Error('Appointment type could not be established')
                }

                if (this.appointmentType != AppointmentType.TICKET) { // ProjectTask
                    const primaryTask = this.appointment.ProjectTasks?.find(t => TaskCode.taskHasCode(t, appointmentTasksModel.Primary.TaskCode))
                    let bookTask = null
                    console.log("Book taskcode",appointmentTasksModel.Book?.TaskCode)
                    if (appointmentTasksModel.Book?.TaskCode){
                        bookTask = this.appointment.ProjectTasks?.find(t => TaskCode.taskHasCode(t, appointmentTasksModel.Book.TaskCode))
                    }
                    
                    if (this.appointmentType == AppointmentType.INSTALLATION){
                        await this.dataDeleteConnectionDate(primaryTask?.Id || this.appointment.ProjectTasks?.[0]?.Id)
                    }
                    let bookTaskFromInst = this.installation.tasks.find(t => t.id == bookTask?.Id)
                    let primaryTaskFromInst = this.installation.tasks.find(t => t.id == primaryTask?.Id)
                    await this.dataDeleteAppointment(this.appointment, bookTaskFromInst, primaryTaskFromInst, this.user, this.reason, this.setOnHold, this.onHoldReason, this.onHoldSubreason)

                    let anonymizedAppointment = this.appointment
                    delete anonymizedAppointment.AddressLong
                    delete anonymizedAppointment.AddressShort
                    delete anonymizedAppointment.Contacts
                    analytics.logEvent('cancel_appointment', anonymizedAppointment)
                } else { // TroubleTicket
                    const taskArray = this.installation.tasks
                    await this.dataDeleteTtAppointment(this.appointment, taskArray, this.user, this.reason)
                } 

            }
            catch (error) {
                swal('Fejl ved aflysning', `Der opstod en fejl under aflysning af aftalen.\nPrøv venligst igen eller kontakt IT. \n\nFejl: ${error.message}`, 'error')
                console.error(error)
                this.isLoading = false
                EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
                return
            }

            try {
                // If this.notify bool then notify customer about cancellation of appointment.
                if (this.notify) {
                    await this.notifyCustomer()
                }
            }
            catch (error) {
                swal('Notificering af kunde fejlede', `Der opstod en fejl under afsendelsen af besked til kunden. Aftalen er blevet slettet men har kunden er ikke blevet notificeret. Fejl: ${error.message}`, 'error')
                console.error(error)
                this.isLoading = false
                EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
                return
            }

            EventBus.$emit('timeline-update-task')

            swal('Aftale aflyst', 'Aftalen blev aflyst og hvis kontaktpersoner skulle notificeres, vil de straks modtage en email/SMS.', 'success')

            this.isLoading = false
            EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_removeAppointment', isActive: false})
            this.clickAway()
            EventBus.$emit('cancellation-list-modal-open-if-any')
        },

        notifyCustomer() {
            const { AddressLong, AddressShort, TimeWindowString } = this.appointment
            const timeWindowObj = this.readTimeWindowString(TimeWindowString)

            if (this.appointment.Contacts && this.appointment.Contacts.Emails) {
                this.dataAddEmail({
                    to: this.appointment.Contacts.Emails.join(','),
                    template: {
                        name: EmailTemplate.APPOINTMENT_CANCELLED,
                        data: {
                            address: AddressLong,
                            date: timeWindowObj.Date.replaceAll('/', '-'),
                            timeFrom: timeWindowObj.Time.From,
                            timeTo: timeWindowObj.Time.To
                        }
                    }
                }).then(() => {
                    console.log('Queued appointment cancellation email')
                })
            }

            if (this.appointment.Contacts && this.appointment.Contacts.Mobiles) {
                this.dataAddSMS({
                    To: this.appointment.Contacts.Mobiles,
                    Template: SMSTemplate.APPOINTMENT_CANCELLED,
                    Data: {
                        Address: AddressShort,
                        Date: timeWindowObj.Date.replaceAll('/', '-'),
                        TimeFrom: timeWindowObj.Time.From,
                        TimeTo: timeWindowObj.Time.To
                    }
                }).then(() => {
                    console.log('Queued appointment cancellation sms')
                })
            }
        }
    },

    watch: {
        isOpen: {
            immediate: true,
            async handler(open) {
                if (open) {this.notify = false
                    this.reason = ''

                    if (!this.installation || !this.isOpen) return
                    EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_watch_isOpen_handler', isActive: true})
                    if (this.appointmentIdProp){
                        this.appointment = await this.dataGetAppointmentById(this.project.id, this.appointmentIdProp)
                    } else {
                        this.appointment = await this.dataGetAppointment(this.project.id, this.installation.tasks[0], this.appointmentType)
                    }
                    EventBus.$emit('function-activity', {functionName: 'RemoveAppointmentModal_watch_isOpen_handler', isActive: false})
                    if (!this.appointment) return // Happens when an appointment is deleted.
                    console.log(`Opened removeAppointmentModal for appointment with id: ${this.appointment.id}`)
                    if (this.appointment.Confirmed) this.notify = true
                }
            }
        },

        onHoldReason: {
            immediate: false,
            handler(){
                switch(this.onHoldReason){
                    case OnHoldReason.CALLER: this.onHoldSubreasonOptions = OnHoldReason.CALLER_DROPDOWN_OPTIONS 
                    break;
                    case OnHoldReason.PROBLEM: this.onHoldSubreasonOptions = OnHoldReason.PROBLEM_DROPDOWN_OPTIONS
                    break;
                    case OnHoldReason.VENDOR: this.onHoldSubreasonOptions = OnHoldReason.VENDOR_DROPDOWN_OPTIONS
                    break;
                    case OnHoldReason.CHANGE: this.onHoldSubreasonOptions = OnHoldReason.CHANGE_DROPDOWN_OPTIONS
                    break;
                    case OnHoldReason.REQUEST: this.onHoldSubreasonOptions = OnHoldReason.REQUEST_DROPDOWN_OPTIONS
                    break;
                }
            }
        }
    }
}
</script>
