/*
======================================================================================================
FORM TO PDF EFB MIXIN

This mixin is used for Energi Fyn Bredbånd functuinallity in Forn To PDF forms.
All EFB specific methods are placed in this mixin.

*/

import EventBus from "../../../EventBus.js";
import swal from 'sweetalert';
import { db, storageRef } from '../../../firebase.js';
import { DataAPI } from '@/lib/DataAPI.js';
// import FormToPdfComponents from '../../lib/Enums/FormToPdfComponents'

import TaskCode from '@/lib/Enums/TaskCode.js'

export const FormToPdfEFBMixin = {

    mixins: [
        db,
        storageRef,
        DataAPI,
    ],

    enums: {
        TaskCode
    },

    data() {
        return {
            // KEY_LENGTH: 8,

            // GLOBAL_PROJECT_ID: "global",
        }
    },

    computed: {
        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },
    },

    methods: {
        /**
         * This method is used to test the EFB functionality
         * 
         * Is called from the FormToPdf page via button click
         * 
         * @param {String} installationLabel - The installation label
         */
        async testEFB() {
            let instLabels = [
                "70317020",
                "70317021",
                "70317022",
            ]

            await this.uploadAttachmenFromForm("FormToPdf/Bredagerløkken/Godkendte specifikke aftaler/Etableringsaftale_Bredagerløkken 165, 167, 169.pdf", instLabels)

        },

        async uploadAttachmenFromForm(firebaseStoragePath, installationLabels){
            let uploadCount = 0
            let errors = []
            for (let instLabel of installationLabels) {
                console.log(`Uploading attachment ${uploadCount + 1} of ${installationLabels.length}`)
                let installation = this.createCustomInstallation({label: instLabel})
                if (!installation.uploaded) {
                    installation = await this.uploadAttachmentFromFormInstallation(firebaseStoragePath, installation)
                }

                if (!installation.uploaded) {
                    errors.push(installation.label)
                }

                uploadCount++
                console.log("Installation after upload", installation)
            }

            if (errors.length) {
                console.error("Errors uploading attachments", errors)
            } else {
                console.log("All attachments uploaded successfully")
            }
        },

        /**
         * This method is used to upload an attachment to PilotBi based on a FormToPDF installation object
         * 
         * If no taskID is provided, the installation label is used to get the installation from PilotBi
         * The installation object is merged with the PilotBi installation object
         * The attachment is uploaded to the taskID
         * 
         * @param {String} firebaseStoragePath - The path to the file in Firebase storage
         * @param {Object} installation - The installation object
         * @returns {Object} - The merged installation object with upload status	
         */
        async uploadAttachmentFromFormInstallation(firebaseStoragePath,installation) {
            console.group("Upload attachment to PilotBi")
            console.log("Installation", installation)

            let taskID = this.getTaskIDForAttachmentUpload(installation)
            let mergedInstallation = installation
            if (!taskID && !installation.label) {
                console.error("No taskID or installation label provided")
                console.groupEnd()
                return
            }

            if (!taskID) {
                console.log("No taskID provided, getting installation:", installation.label)
                let pilotBiInstallation = await this.getInstallation(installation.label)
                mergedInstallation = this.mergeInstallations(installation, pilotBiInstallation)
                taskID = this.getTaskIDForAttachmentUpload(mergedInstallation)
            }

            console.log("taskID", taskID)

            let uploadStatus = {uploaded: false, timestampUploaded: null}
            try {
                uploadStatus = await this.uploadAttachment(firebaseStoragePath, taskID)
            } catch (error) {
                console.error("Error uploading attachment", error)
            }

            console.log("uploadStatus", uploadStatus)
            mergedInstallation.uploaded = uploadStatus.uploaded
            mergedInstallation.timestampUploaded = uploadStatus.timestampUploaded

            console.groupEnd()
            return mergedInstallation
        },



        /**
         * Upload attachment to Pilotbi based on either task ID or installation label
         * 
         * @param {String} firebaseStoragePath - The path to the file in Firebase storage
         * @param {Object} taskID - The task ID to upload the attachment to
         */
        async uploadAttachment(firebaseStoragePath, taskID) {

            EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_uploadAttachment', isActive: true})

            if (!taskID) {
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_uploadAttachment', isActive: false})
                throw new Error("taskID must be provided")
            }

            if (!firebaseStoragePath) {
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_uploadAttachment', isActive: false})
                throw new Error("firebaseStoragePath must be provided")
            }

            // Get firebase storage reference
            let pdfFile = null
            try {
                pdfFile = await this.downloadAttachmentAsBase64(firebaseStoragePath)
            } catch (error) {
                console.error("Error getting storage reference", error)
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_uploadAttachment', isActive: false})
                throw error
            }
            console.log("pdfFile", pdfFile)
            
            try {
                await this.dataPostAttachment(taskID, pdfFile.metadata.name, pdfFile.metadata.contentType, pdfFile.base64Content)
            } catch (error) {
                console.error("Error uploading attachment", error)
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_uploadAttachment', isActive: false})
                return {uploaded: false, timestampUploaded: null}
            }

            EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_uploadAttachment', isActive: false})
            return {uploaded: true, timestampUploaded: new Date().toISOString()}
        },

        /**
         * This method is used to download a PDF file from Firebase storage and convert it to a base64 string
         * 
         * @param {String} firebaseStoragePath - The path to the file in Firebase storage
         * @returns {Object} - File metadata and base64 string
         */
        async downloadAttachmentAsBase64(firebaseStoragePath) {
            EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_downloadAttachmentAsBase64', isActive: true})

            if (!firebaseStoragePath) {
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_downloadAttachmentAsBase64', isActive: false})
                throw new Error("firebaseStoragePath must be provided")
            }

            let attachmentRef = await storageRef.child(firebaseStoragePath)
            let downloadURL = await attachmentRef.getDownloadURL()
            let metadata = await attachmentRef.getMetadata()

            console.log("downloadURL", downloadURL)

            let base64Content = null
            try {
                let response = await fetch(downloadURL)
                let data = await response.blob()

                base64Content = await new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(data);
                    reader.onloadend = () => {
                        let base64Result = reader.result;
                        base64Result = String(base64Result).substring(base64Result.indexOf('base64,') + 7);
                        resolve(base64Result);
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                });

            } catch (error) {
                console.error("Error downloading attachment", error)
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_downloadAttachmentAsBase64', isActive: false})
                throw error
            }

            EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_downloadAttachmentAsBase64', isActive: false})
            return {base64Content, metadata}
        },

        /**
         * Method for returning best task ID for attachment upload
         * 
         * @param {Object} installation - The installation object
         * @returns {String} - The task ID
         */
        getTaskIDForAttachmentUpload(installation) {
            let taskID = null
            
            if (installation.tasks.length) {
                taskID = installation.tasks[0].id
            }

            // If installation contains a task with code TaskCode.FIBERKONSU, use that task
            for (let task of installation.tasks) {
                if (TaskCode.taskHasCode(task, TaskCode.FIBERKONSU)) {
                    taskID = task.id
                    break
                }
            }

            return taskID
        },

        /**
         * Method for merging a custom installation object with a PilotBi installation object
         * 
         * If the property of the custom installation object is null, use the property from the PilotBi installation object
         * Data from the custom installation object will be overwritten by the PilotBi installation object and have first priority
         * Except for the contact arrays should be merged and duplicates removed
         * The isCustom property will be set to false to indicate that a PilotBi installation object is merged
         * 
         * @param {Object} customInstallation - The custom installation object
         * @param {Object} pilotBiInstallation - The PilotBi installation object
         * 
         * @returns {Object} - The merged installation object
         */
        mergeInstallations(customInstallation, pilotBiInstallation) {
            let mergedInstallation = {
                label: customInstallation.label || pilotBiInstallation.label,
                contacts: this.mergeContactArrays(customInstallation.contacts, pilotBiInstallation.contacts),
                address: customInstallation.address || pilotBiInstallation.address,
                association: customInstallation.association || pilotBiInstallation.association,
                tasks: pilotBiInstallation.tasks,
                isCustom: false,
                uploaded: false,
                timestampUploaded: null,
            }

            return mergedInstallation
        },

        /**
         * Method for merging two contact arrays and removing duplicates
         * 
         * @param {Array} customContacts - The custom contacts array
         * @param {Array} pilotBiContacts - The PilotBi contacts array
         * 
         * @returns {Array} - The merged contacts array
         */
        mergeContactArrays(customContacts, pilotBiContacts) {
            let mergedContacts = [...customContacts]

            for (let contact of pilotBiContacts) {
                if (!mergedContacts.some(c => c.name === contact.name)) {
                    mergedContacts.push(contact)
                }
            }

            return mergedContacts
        },


        /**
         * Method for generating a dummy custom installation object
         * 
         * @returns {Object} - The custom installation object
         */
        createCustomInstallation(options = {}) {
            const {label = "", address = "", contacts = []} = options
            return {
                label: label,
                contacts: contacts,
                address: address,
                association: null,
                tasks: [],
                isCustom: true,
                uploaded: false,
                timestampUploaded: null,
            }
        },

        /**
         * This method get the relevant installation data from PilotBi REST API based on the installation label
         * 
         * Gets all service orders for the installation (closed tasks true)
         * For each service order, get all tasks
         * Create task object with taskID, taskNumber, taskCode and taskRole
         * Create installation object with installation label, contacts, address, association, array of tasks and isCustom set to false
         * 
         * @param {String} installationLabel - The installation label
         * @returns {Object} - The installation object
         */
        async getInstallation(installationLabel) {
            EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_getInstallation', isActive: true})

            let installation = {
                label: installationLabel,
                contacts: [],
                address: null,
                association: null,
                tasks: [],
                isCustom: false,
                uploaded: false,
                timestampUploaded: null,
            }

            // Get all service orders for the installation
            try {
                var serviceOrders = await this.dataGetAllServiceOrdersByInst(installationLabel, true)
            } catch (error) {
                console.error('Error getting serviceOrders:',error)
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_getInstallation', isActive: false})
                throw error
            }

            // If theres no service orders, return empty installation
            if (!serviceOrders.length) {
                EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_getInstallation', isActive: false})
                return installation
            }
            
            let chosenServiceOrder = serviceOrders[0] // Default to the first service order

            // If theres more than one service order, throw a swal to let the user select the correct one
            if (serviceOrders.length > 1) {
                let swalText = ''
                for (let so of serviceOrders) {
                    swalText += `Service Order: ${so.number}\n`
                    swalText+= `Oprettet: ${so.createdAt}\n`
                    swalText+= `ISP: ${so.serviceProvider.name}\n`
                    for (let task of so.project.tasks){
                        swalText += `  Task: ${task.number} - ${task.code} - ${task.state.label}\n`
                    }
                    swalText += '\n'
                }

                // Await the user to select the correct service order
                chosenServiceOrder = await swal({
                    title: "Der er mere end én serviceordre til installationen. Vælg den korrekte.",
                    text: swalText,
                    buttons: serviceOrders.reduce((acc, so) => {
                        acc[so.number] = {text: so.number, value: so, visible: true}
                        return acc
                    }, {}),
                })

                console.log("chosenServiceOrder", chosenServiceOrder)
            }

            // Get all tasks from the chosen service order
            let taskArray = []
            for (let task of chosenServiceOrder.project.tasks) {
                taskArray.push({
                    id: task.id,
                    number: task.number,
                    code: task.code,
                    // role: task.role,
                })
            }
            installation.tasks = taskArray
            
            // Get one full task to get the address, association and contacts
            try {
                let fullTask = await this.dataGetProjectTask({id: taskArray[0].id, number: taskArray[0].number}, true)

                installation.address = this.formatAddress(this.getConfiguration(fullTask).address, false)
                installation.contacts = fullTask.contacts
                installation.association = this.getConfiguration(fullTask).association
            } catch (error) {
                console.error(`Error getting task ${taskArray[0].number}`, error)
            }

            EventBus.$emit('function-activity', {functionName: 'FormToPdf_EFB_getInstallation', isActive: false})

            return installation
        },
       


   
    }
}
