
class TaskCode {

    static BOOKBESIGT = 'KONBESIGT'
    static FIBERKONSU = 'FIBERKONSU'
    static PATCH = 'PATCHHUS'
    static BLAES = 'BLAES'
    static BOOKKUNDE = 'BOOKKUNDE'
    static CPE = 'CPE'
    static SPLIDS = 'SPLIDS'
    static UDVID = 'UDVID'
    static KSFIBER = 'KSFIBER'
    static TICKET = 'TICKET'
    static EMPTY = ''

    static AllArray = [
        this.BOOKBESIGT,
        this.FIBERKONSU,
        this.PATCH, 
        this.BLAES, 
        this.SPLIDS,
        this.BOOKKUNDE, 
        this.CPE, 
        this.UDVID, 
        this.KSFIBER,
        this.TICKET, 
        this.EMPTY
    ]

    static BookRoleArray = [
        this.BOOKBESIGT,
        this.BOOKKUNDE,
    ]

    static PrimaryRoleArray = [ // Used for setting the primary task to pending if the booking task is set to open or on hold
        this.FIBERKONSU,
        this.CPE,
        this.TICKET,
        this.UDVID,
    ]

    static ConnectionDateArray = [
        this.CPE,
        this.PATCH,
        this.UDVID,
    ]

    static formatTaskCode(code) {
        if (!code) {
            return code
        }
        return code.replaceAll(/\s/g, '').toUpperCase()
    }

    static taskHasCode(task, code, advancedErrorThrowing = false) {
        //Task code errors
        if (!task || typeof task !== 'object' || Array.isArray(task) || Object.keys(task).length === 0) {
            if (advancedErrorThrowing) {
                throw new Error(`Task is not a valid Object: ${JSON.stringify(task)}`);
            } else {
                console.warn(`taskHasCode: Task is not a valid Object: ${JSON.stringify(task)}`);
                return false
            }
        } 
        const taskCode = task?.code || task?.Code // Task code is called 'code' in PilotBI, and 'Code' in Firebase
        if (!taskCode) {
            if (!task.number && !task.id) {
                console.warn(`taskHasCode: Task has no number or id, only these properties: "${Object.keys(task).join('", "')}"`);
            }
            if (advancedErrorThrowing) {
                throw new Error(`Task code for ${task.number || task.id} is not a valid task code: ${taskCode}`);
            } else {
                console.warn(`taskHasCode: Task code for ${task.number || task.id} is not a valid task code: ${taskCode}`);
                return false
            }
        }

        if (!this.AllArray.includes(taskCode)) {
            if (!this.AllArray.includes(this.formatTaskCode(taskCode))) {
                if (advancedErrorThrowing) {
                    throw new Error('Task code is not a valid task code');
                } else {
                    console.warn(`taskHasCode: Task code for ${task.number} is not a valid task code: ${taskCode}`);
                }
            } else {
                console.warn(`taskHasCode: Task code for ${task.number} is not in the correct format. It should be in uppercase and without spaces: '${this.formatTaskCode(taskCode)}' instead of '${taskCode}'`);
            }
        }

        //Provided code errors
        if (!code) {
            if (advancedErrorThrowing) {
                throw new Error('To check if a task has a code, you need to provide a code');
            } else {
                console.warn('taskHasCode: To check if a task has a code, you need to provide a code');
                return false
            }
        } else if (!this.AllArray.includes(code)) {
            if (!this.AllArray.includes(this.formatTaskCode(code))) {
                if (advancedErrorThrowing) {
                    throw new Error('Provided code is not a valid task code');
                } else {
                    console.warn(`taskHasCode: Provided code is not a valid task code: ${code}`);
                }
            } else {
                console.warn(`taskHasCode: Provided code is not in the correct format. It should be in uppercase and without spaces: '${this.formatTaskCode(code)}' instead of '${code}'`);
                return false
            }
        }

        return this.formatTaskCode(taskCode) === this.formatTaskCode(code)
    }

    static arrHasTaskWithCode(arr, code, advancedErrorThrowing = false) {
        if (!arr || !Array.isArray(arr)) {
            throw new Error('arrHasTaskWithCode: Provided array is not a valid Array');
        }

        return arr.some(task => this.taskHasCode(task, code, advancedErrorThrowing))
    }
}

export default TaskCode