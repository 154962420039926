<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies appointments, internal subtasks and unitwork, will add project: 'Frm3yfPD1PGszrHI9j8s' (Erhverv).
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            Will add <sui-label>projectId: 'Frm3yfPD1PGszrHI9j8s'</sui-label> to all LPCs with <sui-label>ProjectInstallationType</sui-label> '9'.
        </p>
        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '@/firebase.js'

export default {
    name: 'GetNotesForTickets',

    data() {
        return {
            isRunning: false,
            dryRun: false,

            projectId:'Frm3yfPD1PGszrHI9j8s',
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message);
        },

        async triggerDry() {
            this.dryRun = true;
            await this.trigger();
        },

        async triggerNormal() {
            this.dryRun = false;
            await this.trigger();
        },

        async trigger() {
            this.isRunning = true;
            this.log(`----------------`);
            this.log(`Starting process`);

            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`);
            }
            this.log('----------------');

            this.log('Getting notes');
            let notes = [];
            await db.collection('Notes').where('timeStamp', '>=', '2025-02-01T00:00:00').where('timeStamp', '<=', '2025-03-01T00:00:00').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let note = doc.data();
                    note.id = doc.id;
                    notes.push(note);
                });
            });
            this.log(`Got ${notes.length} notes`);

            this.log('Looping through appointments');
            let notesCount = 0;
            const authorEmails = ['bso@fiberlan.dk', 'mar@fiberlan.dk'];
            for (let note of notes){
                if (authorEmails.includes(note.authorEmail.toLowerCase())){
                    notesCount++;
                    this.log(`${note.authorEmail};${note.timeStamp};${note.projectTaskId};${note.configurationItemLabel};${note.noteBody}`);
                }
            }
            this.log(`Found ${notesCount} notes from ${authorEmails.join(', ')}`);

            this.log(`Finished process`);

            this.isRunning = false
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}
</style>